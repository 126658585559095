<template>
  <div ref="container" class="swiper-container c-home-carousel__wrapper">
    <div class="c-home-carousel__navigation">
      <slot name="title" />
      <div v-for="i in slideCount" :key="i" @click="navigate(i - 1)">
        <slot
          :name="`page-${i}`"
          :current-slide="currentSlide == i - 1"
          :autoplay-progress="(1 - autoplayProgress) * 100"
          :handle-click="handleClick"
        ></slot>
      </div>
    </div>

    <div class="swiper-wrapper c-home-carousel__images">
      <div
        v-for="i in slideCount"
        :key="i"
        class="swiper-slide c-home-carousel__image-container"
      >
        <slot :name="`image-${i}`"></slot>
      </div>
    </div>
  </div>
</template>

<script setup>
import { Swiper } from "swiper";
import { ref, onMounted, defineProps } from "vue";
import { Autoplay } from "swiper/modules";

defineProps({
  slideCount: {
    type: Number,
    required: true,
  },
});

const container = ref();
const swiperInstance = ref();
const currentSlide = ref(0);
const autoplayProgress = ref(0);

onMounted(() => {
  swiperInstance.value = new Swiper(container.value, {
    modules: [Autoplay],
    slidesPerView: 1,
    direction: "horizontal",
    breakpoints: {
      1024: {
        direction: "vertical",
      },
    },
    autoplay: {
      delay: 5000,
      pauseOnMouseEnter: true,
    },
    on: {
      slideChange: () => {
        currentSlide.value = swiperInstance.value.activeIndex;
      },
      autoplayTimeLeft(s, time, progress) {
        autoplayProgress.value = progress;
      },
    },
  });
});

const navigate = (index) => {
  if (swiperInstance.value) {
    swiperInstance.value.slideTo(index);
  }
};

const handleClick = (e, bool) => {
  if (!bool) {
    e.preventDefault();
  }
};
</script>
