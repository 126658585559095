<template>
  <div
    ref="container"
    @mouseover="mouseOverHandler"
    @mouseleave="mouseLeaveHandler"
  >
    <slot />
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, computed } from "vue";
import { gsap } from "gsap";
import { SplitText } from "gsap/SplitText";
import { ScrollTrigger } from "gsap/ScrollTrigger";

// Refs
const container = ref(null);
let timeline = null;

// Props
const props = defineProps({
  useMouseTrigger: {
    type: Boolean,
    default: false,
  },
  targetElement: {
    type: String,
    default: "strong",
  },
});

// Computed property to get target text elements
const getTargetText = computed(() => {
  if (!container.value) return [];
  return container.value.querySelectorAll(
    `.gsap-highlighted-text ${props.targetElement}`
  );
});

// Highlight animation function
const highlightTitle = (elements) => {
  if (!timeline || elements.length === 0) return;

  timeline.fromTo(
    elements,
    { backgroundSize: "0% 92%" },
    {
      backgroundSize: "100% 92%",
      duration: 0.75 * elements.length,
      ease: "power2.out",
      stagger: 0.5,
    },
    0
  );
};

// Setup animation timeline
const setupTimeline = () => {
  const highlightedText = getTargetText.value;
  if (!highlightedText.length || props.useMouseTrigger) return;

  timeline = gsap.timeline({
    scrollTrigger: {
      trigger: container.value,
      start: "-=300",
      end: "bottom center",
      once: true,
    },
  });

  timeline.fromTo(
    highlightedText,
    { autoAlpha: 0.5 },
    {
      autoAlpha: 1,
      stagger: 0.5,
    }
  );

  highlightTitle(highlightedText);
};

// Cleanup timeline
const killTimeline = () => {
  if (timeline) {
    timeline.kill();
    timeline = null;
  }
};

// Mouse event handlers
const mouseOverHandler = () => {
  if (!props.useMouseTrigger) return;
  const highlightedText = getTargetText.value;
  timeline = gsap.timeline();
  highlightTitle(highlightedText);
};

const mouseLeaveHandler = () => {
  if (!props.useMouseTrigger || !timeline) return;
  timeline.reverse();
};

// Lifecycle hooks
onMounted(() => {
  gsap.registerPlugin(SplitText, ScrollTrigger);
  setupTimeline();
});

onBeforeUnmount(() => {
  killTimeline();
});
</script>
