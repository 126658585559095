<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.75 5.25C6.75 5.05222 6.80865 4.85888 6.91853 4.69443C7.02841 4.52998 7.18459 4.40181 7.36732 4.32612C7.55005 4.25043 7.75111 4.23063 7.94509 4.26921C8.13907 4.3078 8.31726 4.40304 8.45711 4.54289C8.59696 4.68275 8.6922 4.86093 8.73079 5.05491C8.76937 5.24889 8.74957 5.44996 8.67388 5.63268C8.5982 5.81541 8.47002 5.97159 8.30557 6.08147C8.14112 6.19135 7.94778 6.25 7.75 6.25C7.48479 6.25 7.23043 6.14464 7.0429 5.95711C6.85536 5.76957 6.75 5.51522 6.75 5.25ZM14.75 8C14.75 9.33502 14.3541 10.6401 13.6124 11.7501C12.8707 12.8601 11.8165 13.7253 10.5831 14.2362C9.34971 14.7471 7.99252 14.8808 6.68314 14.6203C5.37377 14.3598 4.17104 13.717 3.22703 12.773C2.28303 11.829 1.64015 10.6262 1.3797 9.31686C1.11925 8.00749 1.25292 6.65029 1.76382 5.41689C2.27471 4.18349 3.13987 3.12928 4.2499 2.38758C5.35994 1.64588 6.66498 1.25 8 1.25C9.78961 1.25199 11.5053 1.96378 12.7708 3.22922C14.0362 4.49466 14.748 6.2104 14.75 8ZM13.25 8C13.25 6.96165 12.9421 5.94661 12.3652 5.08326C11.7883 4.2199 10.9684 3.54699 10.0091 3.14963C9.04978 2.75227 7.99418 2.6483 6.97578 2.85088C5.95738 3.05345 5.02192 3.55346 4.28769 4.28769C3.55347 5.02191 3.05345 5.95738 2.85088 6.97578C2.64831 7.99418 2.75228 9.04978 3.14964 10.0091C3.547 10.9684 4.2199 11.7883 5.08326 12.3652C5.94662 12.9421 6.96165 13.25 8 13.25C9.39193 13.2485 10.7264 12.6949 11.7107 11.7107C12.6949 10.7264 13.2485 9.39193 13.25 8ZM8.75 10.2925V8.25C8.75 7.91848 8.61831 7.60054 8.38389 7.36612C8.14947 7.1317 7.83152 7 7.5 7C7.32288 6.99974 7.15139 7.06217 7.01588 7.17623C6.88038 7.2903 6.78962 7.44864 6.75968 7.62321C6.72974 7.79778 6.76254 7.97731 6.85229 8.13001C6.94203 8.28271 7.08292 8.39872 7.25 8.4575V10.5C7.25 10.8315 7.3817 11.1495 7.61612 11.3839C7.85054 11.6183 8.16848 11.75 8.5 11.75C8.67712 11.7503 8.84862 11.6878 8.98412 11.5738C9.11962 11.4597 9.21038 11.3014 9.24032 11.1268C9.27027 10.9522 9.23746 10.7727 9.14772 10.62C9.05798 10.4673 8.91709 10.3513 8.75 10.2925Z"
      fill="currentColor"
    />
  </svg>
</template>
