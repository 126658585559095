<template>
  <div ref="container" class="c-tabs">
    <ul class="c-tabs__list">
      <slot :set-element="setElement"></slot>
    </ul>
  </div>
</template>

<script setup>
import { ref } from "vue";
const element = ref();
const container = ref();

const setElement = (el) => {
  element.value = el;
};
</script>
