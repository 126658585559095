<template>
  <svg
    width="14"
    height="10"
    viewBox="0 0 14 10"
    fill=""
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.6125 1.5746e-06L5.25 1.67786e-06L5.25 0.416668L11.375 4.16667L-2.54983e-07 4.16667L-1.82131e-07 5.83333L11.375 5.83333L5.25 9.58333L5.25 10L7.6125 10L14 6L14 4L7.6125 1.5746e-06Z"
      fill="currentColor"
    />
  </svg>
</template>
