<template>
  <div ref="listener" class="search">
    <ais-autocomplete v-slot="{ currentRefinement, indices, refine }">
      <section>
        <div class="search__input-container">
          <label for="search" class="sr-only">{{ t("Search...") }}</label>

          <svg-component name="search" class="search__icon" />
          <input
            id="search"
            v-model="query"
            type="search"
            :placeholder="inputPlaceholder"
            name="search"
            :class="`search__input ${
              query &&
              currentRefinement &&
              indices[0].hits.length &&
              isClickOutside
                ? 'search__input--results'
                : ''
            }`"
            @input="debounceInput(refine, query)"
            @keypress.enter="handleEnter"
          />
          <button
            v-if="query"
            class="search__close"
            type="button"
            @click="handleClose"
          >
            ✕
          </button>
        </div>

        <template
          v-if="
            query &&
            query.length >= 3 &&
            currentRefinement &&
            indices[0].hits.length &&
            isClickOutside
          "
        >
          <div class="search__dropdown">
            <div class="u-margin-bottom-tiny">
              <h6>{{ t("Results") }}</h6>
            </div>
            <ul class="results u-margin-bottom-small">
              <li
                v-for="hit in indices[0].hits"
                :key="hit.objectID"
                class="search__item"
              >
                <a
                  class="link link--decorated search__link search__link-decorated"
                  :href="
                    isService
                      ? createLink(hit.title, hit.number, language)
                      : hit.url
                  "
                  :aria-label="
                    getAriaLinkText(
                      isService
                        ? createLink(hit.title, hit.number, language)
                        : hit.url
                    )
                  "
                >
                  <SvgComponent name="search" class="search__item-icon" />

                  <ais-highlight
                    attribute="title"
                    :hit="hit"
                    :class-names="{
                      'ais-Highlight': 'search__highlight',
                      'ais-Highlight-highlighted': 'search__highlight--thick',
                    }"
                  />
                </a>
              </li>
            </ul>
          </div>
        </template>
      </section>
    </ais-autocomplete>
  </div>
</template>

<script setup>
import { debounce } from "lodash";
import { createLink } from "../../serviceDetailLink.js";
import { t } from "../../filters";
import { ref, onMounted, onUnmounted } from "vue";
import getAriaLinkText from "../../helpers.js";
import { SEARCH_TIMEOUT } from "../../algoliaSearchClient.js";

const props = defineProps({
  placeholder: {
    type: String,
    default: "",
  },
  searchBaseUrl: {
    type: String,
    default: "",
  },
  language: {
    type: String,
    default: "",
  },
  isService: {
    type: Boolean,
    default: true,
  },
});

const query = ref("");
const isClickOutside = ref(false);
const listener = ref(null);
const inputPlaceholder = ref("");

const handleEnter = () => {
  if (query.value) {
    const url = new URL(props.searchBaseUrl);
    url.searchParams.set("query", query.value);
    window.location.href = url;
  }
};

const debounceInput = debounce(
  (refine, query) => refine(query),
  SEARCH_TIMEOUT
);

const isInDropdown = (event) => {
  isClickOutside.value = listener.value.contains(event.target);
};

const handleClose = () => {
  query.value = "";
};

onMounted(() => {
  window.addEventListener("click", (event) => isInDropdown(event));

  if (props.placeholder) {
    inputPlaceholder.value = props.placeholder;
  } else {
    inputPlaceholder.value = t("Zoeken in de vragen");
  }
});

onUnmounted(() => {
  window.removeEventListener("click", (event) => isInDropdown(event));
});
</script>
