function toPascalCase(str) {
  return str
    .replace(/[-_](.)/g, (_, group1) => group1.toUpperCase())
    .replace(/^(.)/, (_, group1) => group1.toUpperCase());
}

export default function registerSvgIcons(app) {
  const requireComponent = require.context("./components/svg", false, /\.vue$/);

  requireComponent.keys().forEach((fileName) => {
    const rawName = fileName.replace(/^\.\/(.*)\.vue$/, "$1");
    const pascalName = toPascalCase(rawName);
    const componentName = "Svg" + pascalName;

    const componentConfig = requireComponent(fileName);
    app.component(componentName, componentConfig.default || componentConfig);
  });
}
