<template>
  <svg
    width="14"
    height="10"
    viewBox="0 0 14 10"
    fill=""
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.3875 10L8.75 10L8.75 9.58333L2.625 5.83333L14 5.83333L14 4.16667L2.625 4.16667L8.75 0.416666L8.75 -2.29485e-07L6.3875 -3.32753e-07L-1.74846e-07 4L-2.62268e-07 6L6.3875 10Z"
      fill="currentColor"
    />
  </svg>
</template>
