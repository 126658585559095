<template>
  <slot
    :has-scrolled="hasScrolled"
    :scroll-direction="scrollDirection"
    :scroll-top="scrollTop"
    :desktop-search-is-active="desktopSearchIsActive"
  />
</template>

<script setup>
import anime from "animejs/lib/anime.es.js";
import { onMounted, ref, computed } from "vue";
// eslint-disable-next-line
import { useNavigationScrollController } from "../../hooks/useNavigationScrollController";
import { setCookie, getCookie } from "../../utils/cookies";
import { useStore } from "vuex";

const store = useStore();

const topNavigationHeight = 36;
const showAnimation = ref(false);

const { hasScrolled, scrollDirection, scrollTop } =
  useNavigationScrollController(topNavigationHeight);

const checkAnimationStatus = () => {
  const cookieName = "hasRunAnimation";
  const animationCookie = getCookie(cookieName);

  if (!animationCookie) {
    // No cookie found or expired: Run animation and set a new cookie
    showAnimation.value = true;
    setCookie(cookieName, "true", 3); // Cookie expires in 3 days
  } else if (!localStorage.getItem("hasRunAnimation")) {
    // Cookie exists but animation hasn't run this session
    showAnimation.value = false;
  }

  // Mark animation as run for the current session
  localStorage.setItem("hasRunAnimation", "true");
};

const desktopSearchIsActive = computed({
  get: () => store.state.desktopSearchState.desktopSearchIsActive,
  set: (value) => store.commit("setDesktopSearchIsActive", value),
});

onMounted(() => {
  checkAnimationStatus();
  if (showAnimation.value) {
    anime
      .timeline()
      .add({
        targets: ".navigation__logo",
        duration: 2000,
        scale: [0.2, 1],
        opacity: [0, 1],
        delay: 200,
      })
      .add(
        {
          targets: ".navigation__menu-item",
          duration: 1000,
          translateX: [100, 0],
          easing: "easeOutQuint",
          opacity: [0, 1],
          // eslint-disable-next-line
                    delay: function (el, i) {
            return i * 100;
          },
        },
        "-=1000"
      )
      .add(
        {
          targets: ".navigation__menu-right-item",
          duration: 1000,
          translateX: [-100, 0],
          easing: "easeOutQuint",
          opacity: [0, 1],
          delay: anime.stagger(100, { direction: "reverse" }),
        },
        "-=1400"
      )
      .add(
        {
          targets: ".top-navigation__menu-item",
          duration: 1000,
          translateX: [-100, 0],
          easing: "easeOutQuint",
          opacity: [0, 1],
          delay: anime.stagger(100, { direction: "reverse" }),
        },
        "-=1400"
      );
  }
});
</script>
