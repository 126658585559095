<template>
  <div :class="['t-back-button', { 't-back-button--white': props.isWhite }]">
    <svg-component name="chevron-left"></svg-component>
    <a :href="backUrl" :aria-label="getAriaLinkText(backUrl)">
      <slot />
    </a>
  </div>
</template>

<script setup>
import { computed } from "vue";
import getAriaLinkText from "../../helpers.js";

const props = defineProps({
  parent: {
    type: String,
    required: true,
  },
  isWhite: {
    type: Boolean,
    default: false,
  },
});

const backUrl = computed(() => {
  if (!document.referrer.includes(`${props.parent}?`)) return props.parent;

  return document.referrer;
});
</script>
