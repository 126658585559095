<template>
  <div class="search">
    <ais-autocomplete v-slot="{ currentRefinement, indices, refine }">
      <section>
        <div class="search__input-container">
          <label for="search" class="sr-only">{{ t("Search...") }}</label>
          <svg-component name="search" class="search__icon" />
          <input
            id="search"
            v-model="localQuery"
            type="search"
            :placeholder="t('Zoeken in de vragen')"
            name="search"
            :class="`search__input ${
              localQuery && currentRefinement && indices[0].hits.length
                ? 'search__input--results'
                : ''
            }`"
            @input="debounceInput(refine, localQuery)"
            @keypress.enter="handleEnter"
          />
        </div>

        <template
          v-if="
            localQuery &&
            currentRefinement &&
            indices[0].hits.length &&
            isNewSearch
          "
        >
          <div class="search__dropdown">
            <div class="u-margin-bottom-tiny">
              <h6>{{ t("Resultaten") }}</h6>
            </div>
            <ul class="results u-margin-bottom-small">
              <li
                v-for="hit in indices[0].hits"
                :key="hit.objectID"
                class="search__item"
              >
                <a
                  class="link link--decorated search__link search__link-decorated"
                  :href="createLink(hit.title, hit.number, language)"
                  :aria-label="
                    getAriaLinkText(createLink(hit.title, hit.number, language))
                  "
                >
                  <SvgComponent name="search" class="search__item-icon" />
                  <ais-highlight
                    attribute="title"
                    :hit="hit"
                    :class-names="{
                      'ais-Highlight': 'search__highlight',
                      'ais-Highlight-highlighted': 'search__highlight--thick',
                    }"
                  />
                </a>
              </li>
            </ul>
          </div>
        </template>
      </section>
    </ais-autocomplete>
  </div>
</template>

<script setup>
import { debounce } from "lodash";
import { createLink } from "../../serviceDetailLink";
import { ref } from "vue";
import { t } from "../../filters";
import getAriaLinkText from "../../helpers.js";
import {
  SEARCH_QUERY_MIN_LENGTH,
  SEARCH_TIMEOUT,
} from "../../algoliaSearchClient.js";

const props = defineProps({
  placeholder: {
    type: String,
    default: "Zoeken in de vragen",
  },
  searchBaseUrl: {
    type: String,
    default: "",
  },
  language: {
    type: String,
    required: true,
  },
});
const isNewSearch = ref(false);
const getQuery = () => {
  const url = window.location.search;
  const params = new URLSearchParams(url);
  return params.get("query");
};

const localQuery = ref(getQuery());

const createURL = (localQuery) => {
  return `${props.searchBaseUrl}?query=${encodeURIComponent(localQuery)}`;
};

const handleEnter = () => {
  if (localQuery.value) {
    window.location.href = createURL(localQuery.value);
  }
};

const debounceInput = debounce((refine, localQuery) => {
  if (localQuery.length <= SEARCH_QUERY_MIN_LENGTH) {
    return;
  }
  isNewSearch.value = true;
  refine(localQuery);
}, SEARCH_TIMEOUT);
</script>
