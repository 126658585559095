document.addEventListener("DOMContentLoaded", () => {
  const buttons = document.querySelectorAll("a[data-track-event]");

  buttons.forEach((button) => {
    button.addEventListener("click", (event) => {
      event.preventDefault();

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: button.dataset.trackEvent,
        category: button.dataset.trackCategory,
        label: button.dataset.trackLabel,
        hitType: "event",
      });

      setTimeout(() => {
        if (button.target === "_blank") {
          window.open(button.href);
        }

        location.href = button.href;
      }, 100);
    });
  });
});
