const getPageUri = (url, locale) => {
  const origin = window.location.hostname;
  const baseUrl = `${origin}/${locale !== "nl" ? locale : ""}`;

  // Check if the URL is a valid URL and doesn't contain the base URL
  try {
    const parsedUrl = new URL(url);
    if (!url.includes(baseUrl)) {
      return url;
    }

    // If the URL contains the base URL, return the path part
    return parsedUrl.pathname.replace(/^\/|\/$/g, "");
  } catch (e) {
    // If it's not a valid URL, treat it as a path/slug and clean it up
    return url.replace(/^\/|\/$/g, "");
  }
};

const getAriaLinkText = (url) => {
  if (!url) {
    return null;
  }

  const path = window.location.pathname;
  let locale = "nl";

  if (path && ["en", "de"].includes(path.split("/")[1])) {
    locale = path.split("/")[1];
  }
  const uri = getPageUri(url, locale);

  const translations = {
    nl: "Ga naar ",
    en: "Go to ",
    de: "Gehe zu ",
  };
  const text = translations[locale] || "";

  if (!uri) {
    return;
  }

  return text + uri;
};

export default getAriaLinkText;
