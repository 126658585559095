<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM7.4668 13.3331L7.73347 10.6664L10.1335 3.19977H12.8001L8.00014 13.8664H6.13347L3.20014 7.19977H5.8668L6.93347 10.6664L7.20014 13.3331H7.4668Z"
      fill="currentColor"
    />
  </svg>
</template>
