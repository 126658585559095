<template>
  <ais-menu-select
    v-slot="{ items, refine }"
    :attribute="attribute"
    :sort-by="['name:asc']"
    :transform-items="handleTransformItems"
  >
    <radios-component :items="items" @update:model-value="refine" />
  </ais-menu-select>
</template>

<script setup>
defineProps({
  attribute: {
    type: String,
    required: true,
  },
});

const handleTransformItems = (items) => {
  return items.map((item) => {
    return {
      label: item.label,
      value: item.value,
      checked: item.isRefined,
    };
  });
};
</script>
