<template>
  <section class="search-bar-mobile">
    <button
      class="search-bar-mobile__button search-bar-mobile__button--text"
      :class="buttonClass"
      @click="handleActiveMobile"
    >
      <SvgComponent name="magnify-glass" />
      <span v-if="!isMobile">{{ t("Zoeken") }}</span>
    </button>
    <Teleport v-if="isMounted" to="#search-anker-mobile">
      <ais-instant-search
        :search-client="searchClient"
        :index-name="searchIndex"
      >
        <div
          class="search-bar-mobile__wrapper"
          :class="{
            'search-bar-mobile__wrapper--open': searchIsActive && awaitTick,
            'search-bar-mobile__wrapper--moves-up': movesUp,
            'search-bar-mobile__wrapper--temp-hidden': isTempHidden,
            'search-bar-mobile__wrapper--moves-down':
              !movesUp && searchIsActive,
          }"
        >
          <div class="search-bar-mobile__container">
            <keep-alive>
              <input
                ref="refInput"
                v-model="query"
                type="text"
                class="search-bar-mobile__input"
                :placeholder="t('Waar ben je naar op zoek?')"
                @keyup.enter="handleEnter"
                @keyup.escape="handleEscape"
              />
            </keep-alive>
            <button
              class="search-bar-mobile__button"
              :disabled="searchDisabled"
              @click="handleSearch"
            >
              <SvgComponent
                name="magnify-glass"
                class="search-bar-mobile__icon"
              />
            </button>
          </div>
        </div>
      </ais-instant-search>
    </Teleport>
    <Teleport to="body">
      <div
        class="search-bar-mobile__underlay"
        :class="{
          'search-bar-mobile__underlay--open': searchIsActive,
          'search-bar-mobile__underlay--moves-up': movesUp,
          'search-bar-mobile__underlay--moves-down': !movesUp && searchIsActive,
        }"
        @click="handleActiveMobile"
      ></div>
    </Teleport>
  </section>
</template>
<script setup>
import { searchClient } from "../../algoliaSearchClient";
import { ref, computed, onMounted } from "vue";
import { t } from "../../filters";
import { useStore } from "vuex";

const store = useStore();

const query = ref("");
const refInput = ref();
const movesUp = ref(false);
const isMounted = ref(false);
const isTempHidden = ref(false);
const awaitTick = ref(false);
const clearQuery = ref(null);

const props = defineProps({
  searchIndex: {
    type: String,
    required: true,
  },
  searchBaseUrl: {
    type: String,
    required: true,
  },
  buttonClass: {
    type: String,
    default: null,
  },
  hasScrolled: {
    type: Boolean,
    required: true,
  },
  isMobile: {
    type: Boolean,
    required: true,
  },
});

const searchIsActive = computed({
  get: () => store.state.searchState.searchIsActive,
  set: (value) => store.commit("setSearchIsActive", value),
});

const isOpen = computed({
  get: () => store.state.mobileMenuState.mobileMenuIsOpen,
  set: (value) => store.commit("setMobileMenuIsOpen", value),
});

const searchDisabled = computed(() => {
  return query.value.length < 3;
});

const createUrl = (query) => {
  return `${props.searchBaseUrl}?query=${encodeURIComponent(query)}`;
};

const handleSearch = () => {
  window.location.href = createUrl(query.value);
};
const handleEnter = () => {
  if (searchDisabled.value) return;
  handleSearch();
};

const handleEscape = () => {
  searchIsActive.value = false;
  query.value = "";
};

const handleActiveMobile = () => {
  if (!searchIsActive.value) {
    clearTimeout(clearQuery.value);
    isOpen.value = false;
    searchIsActive.value = true;

    if (!props.hasScrolled) {
      setTimeout(() => {
        awaitTick.value = true;
      }, 150);
    } else {
      awaitTick.value = true;
    }

    setTimeout(() => {
      movesUp.value = false;
      refInput.value.focus();
    }, 200);

    setTimeout(() => {
      refInput.value.focus();
    }, 550);
    return;
  }
  if (searchIsActive.value) {
    // Clear the query value after 5 sec. Beacuse of a wrong click will not automaticly clear the search.
    clearQuery.value = setTimeout(() => {
      query.value = "";
    }, 5000);
    if (!props.hasScrolled) {
      isTempHidden.value = true;
      searchIsActive.value = false;
      awaitTick.value = false;
      setTimeout(() => {
        movesUp.value = true;
        isTempHidden.value = false;
      }, 310);
    } else {
      movesUp.value = true;
    }
    setTimeout(() => {
      searchIsActive.value = false;
      awaitTick.value = false;
    }, 550);
    return;
  }
};
onMounted(() => {
  isMounted.value = true;
});
</script>
<style scoped lang="scss">
#search-anker-mobile {
  @include tablet-landscape {
    display: none;
  }
}

.search-bar-mobile {
  @include tablet-landscape {
    display: none;
  }

  &__wrapper {
    @include tablet-landscape {
      display: none;
    }
  }
  color: inherit;
  &__wrapper {
    position: fixed;
    background-color: white;
    left: 0;
    width: 100vw;
    height: 80px;
    opacity: 0;
    z-index: 19;
    top: 0;
    pointer-events: none;
    transform: translateY(-105px);
    transition: transform 0.5s ease-out;
    &--moves-down {
      transform: translateY(0);
    }
    &--open {
      opacity: 1;
      pointer-events: all;
      top: 85px;
    }
    &--temp-hidden {
      transition: opacity 0.3s ease-out;
      transform: translateY(0);
      top: 85px;
      pointer-events: none;
      opacity: 0;
    }
  }
  &__container {
    margin: 0 auto;
    height: 80px;
    @include container;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 18px;
    border-top: 1px solid $grey-very-light;
  }
  &__input {
    font-size: 28px;
    line-height: 40px;
    width: 100%;
    border: 0;
    font-weight: 500;
    font-family: $title-font-stack;
    &::placeholder {
      color: #ccc;
      font-weight: 500;
    }
    &:active,
    &:focus {
      outline: 0;
    }
  }
  &__icon {
    width: 19px;
    height: 19px;
  }

  &__button {
    background-color: $orange;
    border: 1px solid $orange;
    color: white;
    transition: all 0.1s ease-out;
    width: 60px;
    height: 48px;
    padding: 0;
    &:disabled {
      color: black;
      background-color: transparent;
      border: 1px solid $black;
    }
    &--text {
      color: inherit;
      background-color: transparent;
      border: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      width: 70px;
      height: 70px;
      svg {
        width: 30px;
        height: 30px;
      }
    }
  }
  &__underlay {
    @include tablet-landscape {
      display: none;
    }
    position: fixed;
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    transition: backdrop-filter 0.5s ease-out;
    -webkit-backdrop-filter: blur(0px);
    backdrop-filter: blur(0px);
    pointer-events: none;
    &--open {
      pointer-events: all;
    }
    &--moves-down {
      -webkit-backdrop-filter: blur(10px);
      backdrop-filter: blur(10px);
    }
  }
}
</style>
