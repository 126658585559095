<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 7c0-2.757 2.242-5 5-5 2.757 0 5 2.243 5 5s-2.243 5-5 5c-2.758 0-5-2.243-5-5zm10.602 4.185A6.96 6.96 0 0 0 14 7a7 7 0 1 0-7 7c1.572 0 3.02-.525 4.188-1.401l3.401 3.4h1.413v-1.414l-3.4-3.4z"
      fill="currentColor"
      fill-rule="evenodd"
    />
  </svg>
</template>
