<template>
  <a
    class="c-card-imagery"
    :href="item.url"
    :target="target"
    :aria-label="getAriaLinkText(item.url)"
  >
    <div class="c-card-imagery__visual">
      <div class="c-card-imagery__visual-container">
        <ImageComponent
          :image="item.image.url"
          :alt="item.image.title"
          :title="item.image.title"
          :focal-point="item.image.focalPoint"
          classes-image="u-object-fit"
          sizes="(max-width: 1024px) 100vw, 360px"
          :srcset="item.image.srcsetWebp"
        />
      </div>
    </div>
    <div class="c-card-imagery__content">
      <div class="c-card-imagery__tags">
        <div
          v-for="tag in item.targetAudience"
          :key="tag.title"
          class="c-tag c-tag--orange"
        >
          {{ tag.title }}
        </div>
      </div>
      <div class="c-card-imagery__title">
        <a :href="item.url" class="u-text-clickable" :target="target">
          <h4 class="t-h4">{{ item.title }}</h4>
        </a>
      </div>
      <div class="c-card-imagery__description">
        <p class="t-paragraph t-paragraph--is-smaller">
          {{ item.shortDescription }}
        </p>
      </div>
      <div class="c-card-imagery__link">
        <a
          :href="item.url"
          :target="target"
          class="t-link-arrow t-link-arrow--black t-link-arrow--bold"
          >{{ t("Go to event") }}</a
        >
      </div>
      <div class="c-card-imagery__details">
        <SvgComponent name="calendar" />
        <p class="t-paragraph t-paragraph--is-small">
          {{ item.dateLabel }}
        </p>
      </div>
    </div>
  </a>
</template>

<script setup>
import { computed } from "vue";
import { t } from "../../../filters.js";
import getAriaLinkText from "../../../helpers.js";

const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
});

const target = computed(() => {
  return props.item.type === "eventExternal" ? "_blank" : "_self";
});
</script>
