<template>
  <ais-pagination
    v-slot="{
      currentRefinement,
      nbPages,
      pages,
      isFirstPage,
      isLastPage,
      refine,
      createURL,
    }"
  >
    <div
      :class="[
        'pagination',
        {
          'pagination--is-centered': isCentered,
          'pagination--is-green': isGreen,
        },
      ]"
    >
      <ul class="pagination__list">
        <template v-if="nbPages > 1">
          <li
            :class="{ 'pagination__slot--disabled': isFirstPage }"
            class="pagination__slot pagination__slot--previous"
          >
            <a
              class="pagination__link pagination__link--previous"
              :href="createURL(currentRefinement - 1)"
              :aria-label="getAriaLinkText(createURL(currentRefinement - 1))"
              @click.prevent="
                refine(currentRefinement - 1);
                scrollUp();
              "
            >
              <SvgComponent name="arrow-left" />

              <span class="u-display-none u-display-tablet">
                {{ t("Terug") }}
              </span>
            </a>
          </li>
          <li
            :class="{ 'pagination__slot--disabled': isFirstPage }"
            class="pagination__slot pagination__slot--first"
          >
            <a
              class="pagination__link"
              :href="createURL(0)"
              :aria-label="getAriaLinkText(createURL(0))"
              @click.prevent="
                refine(0);
                scrollUp();
              "
            >
              ..
            </a>
          </li>
          <li
            v-for="page in pages"
            :key="page"
            class="pagination__slot"
            :class="{
              'pagination__slot--active': page === currentRefinement,
              'pagination__slot--active-close':
                page + 1 === currentRefinement ||
                page - 1 === currentRefinement,
            }"
          >
            <a
              class="pagination__link"
              :href="createURL(page)"
              :aria-label="getAriaLinkText(createURL(page))"
              @click.prevent="
                refine(page);
                scrollUp();
              "
            >
              {{ page + 1 }}
            </a>
          </li>

          <li
            :class="{ 'pagination__slot--disabled': isLastPage }"
            class="pagination__slot pagination__slot--last"
          >
            <a
              class="pagination__link"
              :href="createURL(nbPages)"
              :aria-label="getAriaLinkText(createURL(nbPages))"
              @click.prevent="
                refine(nbPages);
                scrollUp();
              "
            >
              ..
            </a>
          </li>
          <li
            :class="{ 'pagination__slot--disabled': isLastPage }"
            class="pagination__slot pagination__slot--next"
          >
            <a
              class="pagination__link pagination__link--next"
              :href="createURL(currentRefinement)"
              :aria-label="getAriaLinkText(createURL(currentRefinement))"
              @click.prevent="
                refine(currentRefinement + 1);
                scrollUp();
              "
            >
              <SvgComponent name="arrow-right" />
              <span class="u-display-none u-display-tablet">
                {{ t("Volgende") }}
              </span>
            </a>
          </li>
        </template>
      </ul>
    </div>
  </ais-pagination>
</template>

<script setup>
import { t } from "../../filters";
import getAriaLinkText from "../../helpers.js";

defineProps({
  isCentered: {
    type: Boolean,
    default: false,
  },
  isGreen: {
    type: Boolean,
    default: false,
  },
});

const scrollUp = () => {
  window.scrollTo({ top: 0, left: 0, behavior: "instant" });
};
</script>
