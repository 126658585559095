<template>
  <div
    ref="vimeoPlayer"
    :class="[
      { 'video-playing': isPlaying },
      fullWidth ? 'full-width' : 'no-full-width',
    ]"
    class="video-container"
  ></div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import Player from "@vimeo/player";

const props = defineProps({
  videoId: {
    type: String,
    required: true,
  },
  width: {
    type: Number,
    default: 1440,
  },
  fullWidth: {
    type: Boolean,
    default: false,
  },
});
const vimeoPlayer = ref(null);
const isPlaying = ref(false);

onMounted(() => {
  const player = new Player(vimeoPlayer.value, {
    id: props.videoId,
    background: true,
    width: props.width,
  });

  player
    .ready()
    .then(() => {
      console.log("Vimeo Player is ready.");
      player.on("play", () => {
        isPlaying.value = true;
      });
    })
    .catch((error) => console.error("Error creating Vimeo player:", error));
});
</script>

<style scoped>
.no-full-width:deep(iframe) {
  width: 100% !important;
  height: 200%;
  top: 50%;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
}

.full-width:deep(iframe) {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 100%;
  min-height: 100%;
  width: 180vh;
  height: 58vw;
}

.video-container {
  opacity: 0;
  transition: opacity 0.5s;
}

.video-playing {
  opacity: 1;
}
</style>
