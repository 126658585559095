<template>
  <svg
    width="7.8"
    height="13"
    viewBox="0 0 6 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.84086 5.38862L1.44079 9.83902C1.22858 10.0537 0.884535 10.0537 0.672347 9.83902L0.159153 9.31995C-0.0526956 9.10568 -0.0531034 8.75841 0.158247 8.54363L3.64538 4.99999L0.158246 1.45637C-0.0531037 1.24159 -0.052696 0.89432 0.159152 0.680047L0.672347 0.160979C0.884557 -0.0536604 1.2286 -0.0536604 1.44079 0.160979L5.84086 4.61136C6.05305 4.826 6.05305 5.17398 5.84086 5.38862Z"
      fill="currentColor"
    />
  </svg>
</template>
