<template>
  <svg
    width="8px"
    height="10px"
    viewBox="0 0 16 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="h-styles"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="Overzicht-Styles-Desktop"
        transform="translate(-628.000000, -5108.000000)"
        fill="currentColor"
        fill-rule="nonzero"
      >
        <g
          id="icon/32x32/menu-nav"
          transform="translate(628.000000, 5108.000000)"
        >
          <polygon
            id="Path"
            transform="translate(8.000000, 10.000000) rotate(-180.000000) translate(-8.000000, -10.000000) "
            points="16 8.3 2 0 0 0 0 1.2 15 10 0 18.8 0 20 2 20 16 11.7"
          ></polygon>
        </g>
      </g>
    </g>
  </svg>
</template>
