import { t } from "../filters";

const transformSearchResults = (items) => {
  return items.map((item) => {
    return {
      id: item.objectID,
      title: item.title,
      tag: item.searchCategories,
      url: item.url,
      target: item.type === "eventExternal" ? "_blank" : "_self",
    };
  });
};

const getResultsMessage = (number, query = null) => {
  if (number === 0 && !query) {
    return "";
  }

  if (number === 0) {
    return t("0 results found for '{query}'", {
      params: { query },
    });
  }

  if (number === 1 && query) {
    return t("1 result for '{query}'", {
      params: { query },
    });
  }

  if (number > 1 && query) {
    return t("{number} results for '{query}'", {
      params: { number, query },
    });
  }

  if (number === 1) {
    return t("1 result");
  }

  return t("{number} results", {
    params: { number },
  });
};

export { transformSearchResults, getResultsMessage };
