<template>
  <ais-current-refinements v-slot="{ items }">
    <div
      ref="options"
      :class="['c-multi-select', { 'c-multi-select--open': isOpen }]"
    >
      <button class="c-multi-select__selected" @click="toggle">
        {{ selectedLabel(items) }}
        <div class="c-multi-select__icon">
          <svg-component
            name="chevron-down"
            :class="['c-chevron', { 'c-chevron--rotated': isOpen }]"
          />
        </div>
      </button>

      <div class="c-multi-select__options">
        <algolia-menu-select v-if="isRadio" :attribute="attribute" />
        <algolia-refinement v-else :attribute="attribute" />
      </div>
    </div>
  </ais-current-refinements>
</template>

<script setup>
import { ref } from "vue";
import { t } from "../../filters";
import { onClickOutside } from "@vueuse/core";

const props = defineProps({
  attribute: {
    type: String,
    required: true,
  },
  isRadio: {
    type: Boolean,
    default: false,
  },
});

const isOpen = ref(false);
const options = ref(null);

const selectedLabel = (items) => {
  const filters = items.find((item) => item.attribute === props.attribute);

  if (!filters || !filters.refinements) {
    return t("Kies filters");
  }

  if (filters.refinements.length === 1) {
    return filters.refinements[0].label;
  }

  return `${filters.refinements[0].label}  +${filters.refinements.length - 1}`;
};

const toggle = () => {
  isOpen.value = !isOpen.value;
};

onClickOutside(options, () => {
  isOpen.value = false;
});
</script>

<style lang="scss">
.c-multi-select {
  position: relative;
  height: 44px;
  border: $border-standard-2023;
  border-radius: 6px;
  min-width: 200px;

  &__selected {
    @include basic-reset;
    cursor: pointer;
    padding-left: 16px;
    padding-right: 40px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    font-family: $title-font-stack;
    font-weight: 200;
  }

  &__icon {
    position: absolute;
    right: 16px;
    top: 0;
    bottom: 0;
    justify-content: center;
    display: flex;
    align-items: center;
  }

  &__options {
    display: none;
    z-index: calc($navbar-index - 1);
    position: absolute;
    bottom: -8px;
    left: 0;
    transform: translateY(100%);
    border: $border-standard-2023;
    border-radius: 6px;
    background-color: $white;
    padding: 16px;
    min-width: max-content;
    width: 100%;
  }

  &--open &__options {
    display: block;
  }
}
</style>
