<template>
  <div
    ref="searchBox"
    :class="[
      'search-box',
      {
        'search-box--big': hasBigStyling,
        'search-box--hide-magnifier': hasBigStyling && !query,
      },
    ]"
  >
    <ais-search-box v-slot="{ refine }">
      <label for="mobile-search" class="sr-only">{{ t("Search...") }}</label>
      <input
        id="mobile-search"
        v-model="query"
        required
        type="search"
        :placeholder="placeHolder"
        class="search-box__input search-box__input--has-close-icon"
        @focus="inputFocus = true"
        @keypress.enter="handleEnter"
        @input="handleInput(query, refine, $event)"
      />
      <span class="search-box__reset-btn" @click="handleCloseClick(refine)" />
    </ais-search-box>

    <ais-index
      v-if="searchIndexSuggestions"
      :index-name="searchIndexSuggestions"
    >
      <ais-hits
        v-if="inputFocus"
        v-slot="{ items }"
        :transform-items="filterHits"
        class="search-autocomplete search-autocomplete--small"
      >
        <ul class="search-autocomplete__list">
          <li
            v-for="item in items"
            :key="item.objectID"
            class="search-autocomplete__item"
          >
            <a
              class="search-autocomplete__link"
              href="#"
              @click.prevent="handleClick(item.query)"
            >
              <ais-highlight
                attribute="query"
                :hit="item"
                highlighted-tag-name="span"
                :class-names="{
                  'ais-Highlight': 'highlight',
                  'ais-Highlight-highlighted': 'highlight__thin',
                }"
              />
            </a>
          </li>
        </ul>
      </ais-hits>
    </ais-index>
  </div>
</template>

<script setup>
import debounce from "lodash/debounce";
import { t } from "../../filters";
import { ref, onMounted } from "vue";
import { onClickOutside } from "@vueuse/core";
import { SEARCH_TIMEOUT } from "../../algoliaSearchClient";

defineProps({
  searchIndexSuggestions: {
    type: String,
    default: "",
  },
  event: {
    type: String,
    required: false,
    default: "",
  },
  placeHolder: {
    type: String,
    default: t("Search..."),
  },
  hasBigStyling: {
    type: Boolean,
    default: false,
  },
});

const inputFocus = ref(false);
const query = ref("");
const searchBox = ref(null);

onMounted(() => {
  const urlParams = new URLSearchParams(window.location.search);
  const queryMounted = urlParams.get("query");
  if (queryMounted) {
    query.value = queryMounted;
  }
});

onClickOutside(searchBox, () => {
  inputFocus.value = false;
});

const handleInput = debounce((query, refineFunction) => {
  refineFunction(query);
}, SEARCH_TIMEOUT);

const handleEnter = () => {
  inputFocus.value = false;
};

const handleCloseClick = (refine) => {
  refine("");
  query.value = "";
};

const filterHits = (items) => {
  return items.filter((item) => {
    return item._highlightResult.query.matchLevel !== "none";
  });
};

const handleClick = (queryValue) => {
  query.value = queryValue;
  inputFocus.value = false;
};
</script>
