<template>
  <div>
    <ais-current-refinements
      v-slot="{ items }"
      :included-attributes="['categoriesRates.title']"
    >
      <div v-if="items[0]?.refinements[0]?.label" class="c-tarif-title">
        <h2 class="t-h4">
          {{ items[0]?.refinements[0]?.label }}
        </h2>
      </div>
    </ais-current-refinements>

    <ais-hits v-slot="{ items }">
      <div v-if="isEmpty(items)">
        <p class="t-text t-text--title u-text-center u-mt-32">
          <slot name="no-results" />
        </p>
      </div>
      <div v-else class="c-tarifs-results">
        <div class="c-tarifs-results__header">
          <div class="c-tarifs-results__header-row">
            <div
              class="c-tarifs-results__header-column c-tarifs-results__header-column--description"
            >
              <slot name="description" />
            </div>
            <div
              class="c-tarifs-results__header-column c-tarifs-results__header-column--price"
            >
              <slot name="tarif" />
            </div>
            <div
              class="c-tarifs-results__header-column c-tarifs-results__header-column--code"
            >
              <slot name="invoice-code" />
            </div>
            <div
              class="c-tarifs-results__header-column c-tarifs-results__header-column--explanation"
            >
              <slot name="explanation" />
            </div>
          </div>
        </div>
        <div class="c-tarifs-results__body">
          <div
            v-for="(item, index) in items"
            :key="index"
            class="c-tarifs-results__body-row"
            @click="handleClickRow(item)"
          >
            <div
              class="c-tarifs-results__body-column c-tarifs-results__body-column--description"
            >
              {{ item.title }}

              <span
                :class="[
                  'c-tarifs-results__footnote',
                  {
                    'c-tarifs-results__footnote--filled': item.footnote,
                  },
                ]"
              >
                <svg-component
                  name="information"
                  class="u-flex-shrink-0"
                  @click="handleOpenModal(item)"
                />
              </span>
            </div>
            <div
              class="c-tarifs-results__body-column c-tarifs-results__body-column--code"
            >
              <span v-if="item.invoiceCode" class="c-tag c-tag--green">
                {{ item.invoiceCode }}
              </span>
            </div>
            <div
              class="c-tarifs-results__body-column c-tarifs-results__body-column--price"
            >
              <span v-if="item.rateType == 'FixedValue'">
                {{ currency(item.rate) }}
              </span>
              <span v-if="item.rateType == 'Percentage'">
                {{ percentage(item.rate) }}
              </span>
            </div>
            <div
              class="c-tarifs-results__body-column c-tarifs-results__body-column--explanation"
            >
              <!-- eslint-disable-next-line vue/no-v-html -->
              <p v-html="item?.description" />
              <button
                v-if="
                  item.footnote ||
                  item.explanation ||
                  (item.description && item.description.length > 100)
                "
                class="u-basic-reset link link--orange link--decorated u-flex-1-0-auto"
                @click="handleOpenModal(item)"
              >
                {{ t("Read more") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </ais-hits>

    <modal-wrapper ref="modal">
      <template #default="{ closeModal }">
        <div class="c-tarif-modal">
          <div class="c-tarif-modal__close">
            <close-button @click="closeModal" />
          </div>
          <div class="c-tarif-modal__header">
            <div class="c-tarif-modal__header-title">
              <h2 class="t-h4">
                {{ modalItem.title }}
              </h2>
            </div>

            <div class="c-tarif-modal__header-info">
              <p>
                {{ currency(modalItem.rate) }}
              </p>
              <span v-if="modalItem.invoiceCode" class="c-tag c-tag--green">
                {{ modalItem.invoiceCode }}
              </span>
            </div>
          </div>
          <div v-if="modalItem.explanation" class="c-tarif-modal__body">
            <h3 class="t-h6 u-mb-4">Toelichting</h3>
            <div class="t-redactor t-redactor--is-smaller">
              {{ modalItem.explanation }}
            </div>
          </div>
          <div v-if="modalItem.footnote" class="c-tarif-modal__footer">
            <h3 class="t-h6 u-mb-4">Voetnoot</h3>
            <div class="t-redactor t-redactor--is-smaller">
              {{ modalItem.footnote }}
            </div>
          </div>
        </div>
      </template>
    </modal-wrapper>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { currency, t, percentage } from "../../filters";
import { isEmpty } from "lodash";

const modal = ref(null);
const modalItem = ref({});

const handleOpenModal = (item) => {
  modalItem.value = item;
  modal.value.openModal();
};

const handleClickRow = (item) => {
  if (window.innerWidth > 767) return;

  handleOpenModal(item);
};
</script>

<style scoped lang="scss">
.c-tarif-title {
  margin-bottom: 16px;
  padding-left: 16px;
  padding-right: 16px;

  @include tablet {
    padding-left: 0;
    padding-right: 0;
  }
}

.c-tarifs-results {
  font-size: 14px;

  &__header-row {
    height: 16px;
    background-color: $green-main;
    color: $white;

    @include tablet {
      display: flex;
      height: auto;
      padding: 16px;
      border-top-left-radius: $border-radius-standard;
      border-top-right-radius: $border-radius-standard;
    }
  }

  &__header-column {
    display: none;

    @include tablet {
      display: flex;
      font-weight: 600;
    }
  }

  &__body-column {
    &--description {
      display: flex;
      align-items: center;

      @include tablet-landscape {
        display: inline-block;
      }
    }
  }

  &__footnote {
    margin-left: auto;

    svg {
      transform: translateY(3px);
    }

    @include tablet {
      display: none;
      margin-left: 8px;

      &:hover {
        cursor: pointer;
      }
    }

    &--filled {
      @include tablet {
        display: inline-block;
      }
    }
  }

  &__body-row {
    display: flex;
    flex-wrap: wrap;
    row-gap: 8px;
    padding: 16px;
    background-color: $white;
    justify-items: space-between;
    border-bottom: $border-medium;

    &:nth-child(odd) {
      background-color: $green-100;
    }

    @include tablet {
      flex-wrap: nowrap;
      border-left: $border-medium;
      border-right: $border-medium;

      &:last-child {
        border-bottom-left-radius: $border-radius-standard;
        border-bottom-right-radius: $border-radius-standard;
      }
    }
  }

  &__body-row,
  &__header-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    column-gap: 12px;

    @include tablet {
      grid-template-columns: 5fr 100px 100px 7fr;
    }
  }

  &__body-column,
  &__header-column {
    &--description {
      grid-column: 1 / -1;

      @include tablet {
        grid-column: auto;
        order: 0;
      }
    }

    &--price {
      justify-self: end;

      @include tablet {
        justify-self: start;
        order: 1;
      }
    }

    &--code {
      @include tablet {
        order: 2;
      }
    }

    &--explanation {
      display: none;

      @include tablet {
        display: block;
        order: 3;

        p {
          display: inline;
          margin-right: 4px;
        }

        button {
          display: inline;
        }
      }
    }
  }
}

.c-tarif-modal {
  position: relative;
  width: 90vw;
  background-color: $white;
  padding: 16px;
  border: $border-medium;
  border-radius: $border-radius-standard;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  max-width: 555px;

  &__close {
    position: absolute;
    top: 16px;
    right: 16px;
  }

  &__header {
    display: grid;
    grid-template-columns: repeat(2, auto);
    justify-content: start;
    row-gap: 16px;
    column-gap: 8px;
    padding-bottom: 16px;
  }

  &__header-title {
    grid-column: 1 / -1;
    padding-right: 24px;
  }

  &__body {
    padding-top: 16px;
    border-top: $border-medium;
    margin-bottom: 16px;
  }

  &__footer {
    padding-top: 16px;
    border-top: $border-medium;
  }

  &__header-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 16px;
  }
}
</style>
