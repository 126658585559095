<template>
  <div class="c-switcher-links">
    <div
      class="c-switcher-links__active-item"
      :class="{ 'c-switcher-links__active-item--open': isActive }"
      @click="handleToggleDropdown"
    >
      <slot name="active-item" />
      <SvgComponent
        name="chevron-down"
        class="c-switcher-links__language-switcher-icon c-switcher-links__language-switcher-icon--chevron"
        :class="{
          'c-switcher-links__language-switcher-icon--open': isActive,
        }"
      />
    </div>
    <div
      class="c-switcher-links__items-list"
      :class="{ 'c-switcher-links__items-list--open': isActive }"
    >
      <div
        v-for="item in items"
        :key="item.title"
        :class="[
          'c-switcher-links__item',
          `c-switcher-links__item--${item.languageCode}`,
          {
            'c-switcher-links__item--active': item.current,
            'c-switcher-links__item--disabled': !item.url,
          },
        ]"
      >
        <a
          class="c-switcher-links__link"
          :href="item.url"
          :aria-label="getAriaLinkText(item.url)"
          @click="handleDataLayerEvent(item)"
        >
          <span class="c-switcher-links__icon"></span>
          {{ item.label }}
        </a>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import getAriaLinkText from "../../helpers.js";
import SvgComponent from "../_partials/SvgComponent.vue";

defineProps({
  /**
   * Object with links, with language code as key
   *
   * @typedef {Object} LanguageItem
   * @property {string} title - The title of the language item.
   * @property {string} label - The label of the language item.
   * @property {string} url - The URL associated with the language item.
   * @property {string} languageCode - The language code (e.g., 'nl', 'de', 'en').
   * @property {boolean} current - Indicates if this language item is the current one.
   *
   * @example
   * {
   *   "nl": {
   *     "title": "Title",
   *     "label": "Nederlands",
   *     "url": "https://royalfloraholland.com/...",
   *     "languageCode": "nl",
   *     "current": true/false
   *   }
   * }
   */
  items: {
    type: Object,
    default: () => ({}),
  },
});

const isActive = ref(false);

const handleToggleDropdown = () => {
  isActive.value = !isActive.value;
};

const handleDataLayerEvent = (item) => {
  window.dataLayer.push({
    event: "languageSwitcher",
    language: item.languageCode,
  });
};
</script>
