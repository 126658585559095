<template>
  <svg
    width="22px"
    height="11px"
    viewBox="0 0 22 11"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="h-styles"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="Overzicht-Styles-Desktop"
        transform="translate(-681.000000, -5165.000000)"
        fill="currentColor"
        fill-rule="nonzero"
      >
        <g
          id="icon/32x32/chevron-down-copy-2"
          transform="translate(681.000000, 5165.000000)"
        >
          <polygon
            id="Path"
            points="12.4 11 22 1.4 22 0 22 0 20.6 0 11 9.6 1.4 0 0 0 0 1.4 9.6 11"
          ></polygon>
        </g>
      </g>
    </g>
  </svg>
</template>
