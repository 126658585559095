<template>
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M40 0C17.9072 0 0 17.9072 0 40C0 62.0928 17.9072 80 40 80C41.7345 80 43.4365 79.8779 45.1059 79.6661C52.4186 78.7296 59.1042 75.8306 64.6335 71.5065C73.9821 64.1857 80 52.8013 80 40C80.0081 17.9072 62.0928 0 40 0Z"
      fill="#46A667"
    />
    <mask
      id="mask0_1372_18596"
      style="mask-type: luminance"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="80"
      height="80"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M40 0C17.9072 0 0 17.9072 0 40C0 62.0928 17.9072 80 40 80C41.7345 80 43.4365 79.8779 45.1059 79.6661C52.4186 78.7296 59.1042 75.8306 64.6335 71.5065C73.9821 64.1857 80 52.8013 80 40C80.0081 17.9072 62.0928 0 40 0Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_1372_18596)">
      <path
        d="M53.8037 39.3401L50.6359 36.2701L59.105 27.5323L50.3672 19.0714L53.4372 15.8955L65.3428 27.4346L53.8037 39.3401Z"
        fill="white"
      />
      <rect
        x="37.0039"
        y="25.2769"
        width="25.2199"
        height="4.41368"
        fill="white"
      />
      <path
        d="M29.0967 31.3434L32.2645 28.2652L23.7954 19.5274L32.5332 11.0665L29.4632 7.89062L17.5576 19.4297L29.0967 31.3434Z"
        fill="white"
      />
      <rect
        x="20.6758"
        y="17.272"
        width="25.2199"
        height="4.41368"
        fill="white"
      />
      <path
        d="M70.1554 51.5714C70.1554 51.5714 70.1554 51.5714 70.1473 51.5714L55.6603 48.0616C54.4551 47.7685 53.2499 48.5095 52.9567 49.7147C52.6636 50.9118 53.3965 52.117 54.5935 52.4183C54.5935 52.4183 54.5935 52.4183 54.6017 52.4183L58.7303 53.4199L50.7255 56.0584L43.087 53.5991C42.1424 53.2978 41.1326 53.8108 40.8313 54.7554C40.53 55.7 41.0431 56.7098 41.9877 57.0111L50.7417 59.8287L63.5349 55.6186C63.9665 55.4802 64.3085 55.187 64.5203 54.8206L69.0887 55.9281C70.2939 56.2212 71.4991 55.4802 71.7922 54.2831C72.0854 53.086 71.3525 51.8808 70.1554 51.5795V51.5714Z"
        fill="#AE6E3E"
      />
      <path
        d="M51.0177 60.5701C51.3271 60.6434 51.6529 60.6434 51.9705 60.5701L70.4314 55.676C71.4574 55.4072 72.0682 54.3486 71.7995 53.3225C71.5307 52.2965 70.4721 51.6857 69.446 51.9545L51.7099 56.6532L45.6105 53.4284C44.6741 52.9317 43.5096 53.29 43.0128 54.2264C42.5161 55.1629 42.8744 56.3274 43.8109 56.8242L50.5698 60.3991C50.7083 60.4724 50.8548 60.5294 51.0096 60.5701H51.0177Z"
        fill="#D4916D"
      />
      <path
        d="M79.895 51.2948H69.9113C69.9113 51.2948 69.8869 51.2948 69.8788 51.2948C69.5856 51.2948 69.3087 51.3436 69.04 51.4169C68.983 51.4332 68.926 51.4495 68.869 51.4658L52.7859 57.2475L47.8348 53.5668C47.1426 53.0537 46.1654 53.2003 45.6442 53.8925C45.1312 54.5847 45.2778 55.5619 45.97 56.083L51.8087 60.4153C51.9146 60.4967 52.0286 60.5537 52.1507 60.6026C52.2159 60.6352 52.2892 60.6677 52.3625 60.6922C52.3625 60.6922 52.3625 60.6922 52.3706 60.6922C52.5009 60.741 52.8592 60.8062 53.0791 60.7654C53.1687 60.7573 53.2582 60.741 53.3478 60.7166L70.5058 57.5163C70.5872 57.5 70.6605 57.4837 70.7338 57.4593H79.8788C79.8788 57.4593 79.9032 57.4593 79.9113 57.4593C81.597 57.443 82.9651 56.0668 82.9651 54.3729C82.9651 52.671 81.5807 51.2866 79.8788 51.2866L79.895 51.2948Z"
        fill="#F8C797"
      />
      <rect
        x="73.8115"
        y="51.1885"
        width="5.56189"
        height="8.91694"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M74.5771 58.6076C74.5771 59.0066 74.9029 59.3323 75.3019 59.3323C75.7009 59.3323 76.0267 59.0066 76.0267 58.6076C76.0267 58.2085 75.7009 57.8828 75.3019 57.8828C74.9029 57.8828 74.5771 58.2085 74.5771 58.6076ZM75.2686 58.7783C75.2686 58.8434 75.2116 58.9004 75.1464 58.9004C75.0813 58.9004 75.0243 58.8434 75.0243 58.7783C75.0243 58.7131 75.0813 58.6561 75.1464 58.6561C75.2116 58.6561 75.2686 58.7131 75.2686 58.7783ZM75.456 58.5664C75.5211 58.5664 75.5781 58.5094 75.5781 58.4443C75.5781 58.3791 75.5211 58.3221 75.456 58.3221C75.3908 58.3221 75.3338 58.3791 75.3338 58.4443C75.3338 58.5094 75.3908 58.5664 75.456 58.5664Z"
        fill="#FF6800"
      />
      <rect
        x="76.5967"
        y="51.0747"
        width="8.08632"
        height="11.4007"
        fill="#3A4D41"
      />
      <path
        d="M9.25118 51.1075C9.25118 51.1075 9.25118 51.1075 9.25932 51.1075L23.7463 47.5977C24.9515 47.3046 26.1567 48.0456 26.4499 49.2508C26.743 50.4479 26.0101 51.6531 24.8131 51.9544C24.8131 51.9544 24.8131 51.9544 24.8049 51.9544L20.6763 52.956L28.6811 55.5945L36.3196 53.1352C37.2642 52.8339 38.274 53.3469 38.5753 54.2916C38.8766 55.2362 38.3636 56.246 37.4189 56.5473L28.6649 59.3648L15.8717 55.1547C15.4401 55.0163 15.0981 54.7232 14.8864 54.3567L10.318 55.4642C9.11274 55.7574 7.90753 55.0163 7.61437 53.8192C7.32121 52.6222 8.05411 51.417 9.25118 51.1157V51.1075Z"
        fill="#AE6E3E"
      />
      <path
        d="M25.57 44.8288C24.6336 37.85 27.5896 30.6269 32.9805 26.7588C33.5505 34.0715 30.5782 41.4168 25.57 44.8288Z"
        fill="#3A4D41"
      />
      <path
        d="M26.2217 43.0699C27.6305 38.0781 29.5686 33.2247 32.0116 28.6318C30.2933 33.0781 28.5751 37.5243 26.2217 43.0699Z"
        fill="#C0DDC5"
      />
      <path
        d="M24.2838 43.746C26.2545 37.5733 24.6095 30.3095 20.1958 25.7085C19.4141 28.5424 18.7789 31.4577 18.9255 34.4056C19.072 37.3453 20.09 40.3339 24.2838 43.746Z"
        fill="#3A4D41"
      />
      <path
        d="M23.3391 41.5958C22.8668 37.0762 21.9221 32.5974 20.5215 28.2651C21.0915 32.3694 21.9791 36.4247 23.3391 41.5958Z"
        fill="#C0DDC5"
      />
      <path
        d="M26.2051 44.8286C26.2051 42.2065 27.2963 39.5924 29.21 37.5973C31.1236 35.6022 33.8517 34.2504 36.7507 33.8433C37.0194 36.2618 35.7328 38.6478 33.8761 40.4231C32.0194 42.1902 29.6416 43.4524 26.2051 44.8205L26.2051 44.8286Z"
        fill="#00CD81"
      />
      <path
        d="M26.5391 44.2343C29.1205 40.8385 32.2312 37.8255 35.8632 35.1626C32.6791 38.2001 29.5032 41.2375 26.5391 44.2343Z"
        fill="#354E40"
      />
      <path
        d="M24.211 44.9918C21.2468 39.2019 22.0937 31.7426 26.3119 26.5879C27.9976 29.3403 28.7305 32.6221 28.3722 35.8306C28.0139 39.0309 26.5562 42.1335 24.211 44.9918Z"
        fill="#00CD81"
      />
      <path
        d="M24.2842 44.096C24.4389 39.267 25.0252 34.4461 26.0269 29.7148C25.6685 34.5601 25.0985 39.3891 24.2842 44.096Z"
        fill="#354E40"
      />
      <path
        d="M22.598 44.8287C23.0622 42.2473 22.4677 39.4867 20.9368 37.1984C19.414 34.9101 16.971 33.1023 14.186 32.2065C13.4856 34.5355 14.3244 37.1088 15.8309 39.1772C17.3374 41.2456 19.4547 42.8906 22.5899 44.8287H22.598Z"
        fill="#00CD81"
      />
      <path
        d="M22.2882 44.1041C21.1645 40.1953 18.0456 37.1986 15.0488 34.3892C17.6547 37.4836 18.697 38.2572 22.2882 44.1041Z"
        fill="#354E40"
      />
      <path
        d="M32.9809 42.0278V49.8373C32.9809 50.6516 32.8832 51.4252 32.704 52.1418C32.0444 54.7803 30.2855 56.6695 28.2252 56.6695H22.0607C19.4304 56.6695 17.2969 53.6076 17.2969 49.8373V42.0278H32.9809Z"
        fill="#FF6800"
      />
      <path
        d="M32.9807 42.0278V49.8373C32.9807 50.6516 32.883 51.4252 32.7038 52.1418C31.8325 53.5995 30.5377 54.5278 29.0963 54.5278H22.9318C20.3015 54.5278 18.168 51.4659 18.168 47.6956V42.0278H32.9807V42.0278Z"
        fill="#FF6800"
      />
      <path
        d="M28.3881 60.1057C28.0786 60.179 27.7529 60.179 27.4353 60.1057L8.9744 55.2116C7.94834 54.9429 7.33759 53.8842 7.60632 52.8582C7.87505 51.8321 8.93368 51.2214 9.95974 51.4901L27.6959 56.1888L33.7952 52.964C34.7317 52.4673 35.8962 52.8256 36.393 53.7621C36.8897 54.6986 36.5314 55.8631 35.5949 56.3598L28.836 59.9347C28.6975 60.008 28.5509 60.065 28.3962 60.1057H28.3881Z"
        fill="#D4916D"
      />
      <path
        d="M-0.4879 50.8304H9.49581C9.49581 50.8304 9.52024 50.8304 9.52839 50.8304C9.82155 50.8304 10.0984 50.8793 10.3672 50.9526C10.4242 50.9688 10.4812 50.9851 10.5382 51.0014L26.6212 56.7832L31.5724 53.1024C32.2645 52.5894 33.2417 52.7359 33.7629 53.4281C34.2759 54.1203 34.1294 55.0975 33.4372 55.6187L27.5984 59.9509C27.4926 60.0324 27.3785 60.0894 27.2564 60.1382C27.1913 60.1708 27.118 60.2034 27.0447 60.2278C27.0447 60.2278 27.0447 60.2278 27.0365 60.2278C26.9062 60.2767 26.5479 60.3418 26.3281 60.3011C26.2385 60.293 26.1489 60.2767 26.0593 60.2522L8.90135 57.0519C8.81992 57.0356 8.74663 57.0193 8.67334 56.9949H-0.4879C-0.4879 56.9949 -0.51233 56.9949 -0.520473 56.9949C-2.20614 56.9786 -3.57422 55.6024 -3.57422 53.9086C-3.57422 52.2066 -2.18985 50.8223 -0.4879 50.8223V50.8304Z"
        fill="#F8C797"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.10366 57.4185C3.70464 57.4185 3.37891 57.7442 3.37891 58.1432C3.37891 58.5422 3.70464 58.868 4.10366 58.868C4.50268 58.868 4.82842 58.5422 4.82842 58.1432C4.82842 57.7442 4.50268 57.4185 4.10366 57.4185ZM4.25873 58.1922C4.32388 58.1922 4.38088 58.2492 4.38088 58.3144C4.38088 58.3795 4.32388 58.4365 4.25873 58.4365C4.19358 58.4365 4.13658 58.3795 4.13658 58.3144C4.13658 58.2492 4.19358 58.1922 4.25873 58.1922ZM4.07131 57.9804C4.07131 57.9153 4.0143 57.8583 3.94916 57.8583C3.88401 57.8583 3.82701 57.9153 3.82701 57.9804C3.82701 58.0456 3.88401 58.1026 3.94916 58.1026C4.0143 58.1026 4.07131 58.0456 4.07131 57.9804Z"
        fill="white"
      />
      <path
        d="M-7.4912 50.6187L-7.51562 60.8222H5.97786L6.00229 50.6187H-7.4912Z"
        fill="#354E40"
      />
      <circle cx="48.135" cy="42.1741" r="7.38599" fill="#FF6800" />
      <path
        d="M44.2271 43.6482H43.4209V42.7442H44.1131C44.0968 42.5651 44.0968 42.3941 44.0968 42.2068V42.0439H43.4209V41.14H44.1619C44.4632 39.0716 45.701 37.8257 47.9242 37.8257C50.1473 37.8257 51.1815 38.982 51.5235 40.4804L49.6098 40.969C49.4225 40.114 48.9828 39.6742 48.1033 39.6742C47.2238 39.6742 46.7841 40.1547 46.6538 41.1319H49.0479V42.0358H46.6212V42.7442H48.3965V43.6482H46.7189C46.9062 44.3811 47.346 44.6661 48.1196 44.6661C49.0398 44.6661 49.504 44.2263 49.6343 43.1026L51.5887 43.4283C51.3362 45.4315 50.0496 46.5228 47.8997 46.5228C45.7499 46.5228 44.6017 45.5537 44.2352 43.64L44.2271 43.6482Z"
        fill="white"
      />
    </g>
  </svg>
</template>
