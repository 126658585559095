<template>
  <nav
    class="c-navigation-jobs-mobile"
    :class="{
      'c-navigation-jobs-mobile--active': activeItem,
    }"
  >
    <div class="c-navigation-jobs-mobile__bar">
      <div class="c-navigation-jobs-mobile__container">
        <slot
          :active-item="activeItem"
          :handle-active-item-click="handleActiveItemClick"
          name="bar"
        ></slot>
        <div
          class="c-navigation-jobs-mobile__menu-item c-navigation-jobs-mobile__menu-item--hamburger"
        >
          <button
            class="hamburger"
            :class="{ 'hamburger--open': menuActive }"
            @click="handleActiveItemClick(menuActive ? false : 'menu-items')"
          >
            <span class="sr-only">{{ t("toggle_navigatie") }}</span>
            <div class="hamburger__bar hamburger__bar--1"></div>

            <div class="hamburger__bar hamburger__bar--2"></div>

            <div class="hamburger__bar hamburger__bar--3"></div>
          </button>
        </div>
      </div>
    </div>

    <div class="c-navigation-jobs-mobile__menu">
      <div class="c-navigation-jobs-mobile__menu-container">
        <slot name="menu-items" :menu-active="menuActive"></slot>

        <slot name="menu-cta"></slot>

        <slot
          :active-item="activeItem"
          :handle-active-item-click="handleActiveItemClick"
          name="menu-quick-links"
        ></slot>

        <slot name="menu-language-switcher"></slot>
      </div>
    </div>
  </nav>
</template>

<script setup>
import { t } from "../../filters";
import { ref, computed } from "vue";
import { useStore } from "vuex";

const store = useStore();
const activeItem = ref("");

defineProps({
  modifier: {
    type: String,
    default: "",
  },
});

// const showMenuItems = computed(() => activeItem.value === "menu-items");
const menuActive = computed(
  () => activeItem.value && activeItem.value !== "menu-search"
);

const handleActiveItemClick = (activeItemValue) => {
  activeItem.value = activeItemValue;
  store.commit("resetItems");
};
</script>
