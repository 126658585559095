<template>
  <div class="header-slides">
    <div class="header-slide">
      <transition-group
        name="slide-left-slow"
        tag="div"
        class="header-slide__section"
      >
        <div
          v-for="(slide, index) in items"
          v-show="index === selectedIndex"
          :key="`header-slide-${index}`"
          class="header-slide__container"
        >
          <div class="header-slide__content">
            <div class="header-slide__title">
              <h1>{{ slide.blockTitle }}</h1>
            </div>

            <div v-if="slide.button.url" class="header-slide__cta">
              <a
                class="button"
                :target="slide.button.targetString"
                :href="slide.button.url"
                :aria-label="getAriaLinkText(slide.button.url)"
                >{{ slide.button.text }}</a
              >
            </div>
          </div>
        </div>
      </transition-group>

      <div class="header-slide__leafs-wrapper">
        <div class="header-slide__leafs">
          <div class="rfh-leafs--diagonal">
            <div class="leaf leaf--left"></div>
            <div class="leaf leaf--right">
              <transition-group name="fade-slow" class="object-fit">
                <a
                  v-for="(slide, index) in items"
                  :key="`header-slide-img-${index}`"
                  class="object-fit"
                  :href="items[selectedIndex].button.url"
                  :aria-label="getAriaLinkText(items[selectedIndex].button.url)"
                  :target="items[selectedIndex].button.targetString"
                >
                  {{ slide.button.text }}
                  <img
                    v-show="index === selectedIndex"
                    class="object-fit"
                    :src="slide.image.url"
                    :style="slide.image.style"
                  />
                </a>
              </transition-group>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="header-slides__slide-indicators">
      <div
        v-for="(item, index) in items"
        :key="`header-slide-indicator-${index}`"
        class="header-slides__slide-indicator"
        :class="{ 'is-active': index === selectedIndex }"
        @click="changeSlide(index)"
      >
        {{ index + 1 }}
      </div>
    </div>

    <div class="header-slides__slide-controls">
      <div class="header-slides__slide-controls__prev" @click="prev()" />
      <div class="header-slides__slide-controls__next" @click="next()" />
    </div>

    <div class="header-slides__ie-header">
      <div class="header-slides__ie-image-wrapper">
        <transition-group name="fade-slow">
          <img
            v-for="(slide, index) in items"
            v-show="index === selectedIndex"
            :key="`header-slides__ie-image-${index}`"
            class="header-slides__ie-image"
            :src="slide.image.url"
          />
        </transition-group>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import getAriaLinkText from "../helpers.js";

const props = defineProps({
  items: {
    required: true,
    type: Array,
  },
});

const selectedIndex = ref(0);
const timer = ref(null);

const stopRotation = () => {
  clearInterval(timer.value);
};

const next = () => {
  stopRotation();

  selectedIndex.value === props.items.length - 1
    ? (selectedIndex.value = 0)
    : (selectedIndex.value += 1);

  timer.value = setInterval(next, 5000);
};

const startRotation = () => {
  timer.value = setInterval(next, 5000);
};

const prev = () => {
  stopRotation();

  selectedIndex.value === 0
    ? (selectedIndex.value = props.items.length - 1)
    : (selectedIndex.value -= 1);

  startRotation();
};

const changeSlide = (index) => {
  stopRotation();
  selectedIndex.value = index;
  startRotation();
};

onMounted(startRotation);
</script>
