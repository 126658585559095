<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.166 7.4165H2.83268L1.91602 8.33317V19.3332L2.83268 20.2498H21.166L22.0827 19.3332V8.33317L21.166 7.4165ZM21.166 19.3332H2.83268V8.33317H21.166V19.3332Z"
      fill="black"
    />
    <path
      d="M17.5007 3.75H6.50065L5.58398 4.66667V7.41667L6.50065 8.33333H17.5007L18.4173 7.41667V4.66667L17.5007 3.75ZM17.5007 7.41667H6.50065V4.66667H17.5007V7.41667Z"
      fill="black"
    />
  </svg>
</template>
