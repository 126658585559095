<template>
  <ais-instant-search
    :search-client="searchClient"
    :index-name="searchIndex + '_query_suggestions'"
    :future="{ preserveSharedStateOnUnmount: false }"
  >
    <ais-autocomplete v-slot="{ currentRefinement, indices, refine }">
      <div
        class="temp-search-bar"
        :class="{
          'temp-search-bar--active': isActive,
          'temp-search-bar--hide-mobile': hideMobile,
        }"
      >
        <button :class="buttonClass">
          <!-- <button :class="buttonClass" @click.stop="updateActiveState"> -->
          <slot name="icon" />

          <template v-if="!isActive">
            {{ labels.search }}
          </template>
        </button>
        <div
          class="temp-search-bar__container"
          :class="{
            'temp-search-bar__container--active': showContent,
          }"
        >
          <label for="search" class="sr-only">{{ t("Search...") }}</label>
          <svg-component name="search" class="search__icon" />
          <input
            id="search"
            v-model="query"
            type="text"
            name="search"
            class="temp-search-bar__input"
            :placeholder="t('Zoeken...')"
            :class="{
              'temp-search-bar__input--full-width': fullWidthInput,
            }"
            @input="() => debounceInput(refine, query)"
            @keyup.enter="handleEnter"
            @blur="isMobile ? () => {} : updateActiveState"
          />

          <div
            class="temp-search-bar__content"
            :class="{
              'temp-search-bar__content--active': showContent,
            }"
          >
            <ul>
              <li v-for="hit in indices[0].hits" :key="hit.objectID">
                <a class="link link--decorated" :href="createURL(hit.query)">
                  <ais-highlight
                    attribute="query"
                    :hit="hit"
                    :class-names="{
                      'ais-Highlight': 'highlight',
                      'ais-Highlight-highlighted': 'highlight__thin',
                    }"
                  />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </ais-autocomplete>
  </ais-instant-search>
</template>

<script setup>
import { debounce } from "lodash";
import {
  searchClient,
  SEARCH_QUERY_MIN_LENGTH,
  SEARCH_TIMEOUT,
} from "../../algoliaSearchClient";
import { t } from "../../filters";
import { ref, computed, onMounted, watch } from "vue";
import { useStore } from "vuex";
const store = useStore();

const props = defineProps({
  buttonClass: {
    type: [String, Array, Object],
    default: null,
  },
  searchIndex: {
    type: String,
    required: true,
  },
  searchBaseUrl: {
    type: String,
    required: true,
  },
  popularTerms: {
    type: Array,
    default: () => [],
  },
  labels: {
    type: Object,
    required: true,
  },
  fullWidthInput: {
    type: Boolean,
    default: false,
  },
  hideMobile: {
    type: Boolean,
    default: false,
  },
});

const input = ref();

const showContent = ref(false);
const query = ref("");
const isMobile = ref(true);

const isActive = computed({
  get: () => store.state.searchState.searchIsActive,
  set: (value) => store.commit("setSearchIsActive", value),
});

watch(isActive, (value) => {
  console.log(value);
  if (value) {
    showContent.value = true;
    input.value?.focus();
  } else {
    showContent.value = false;
  }
});

const debounceInput = debounce((refine, query) => {
  if (query.length <= SEARCH_QUERY_MIN_LENGTH) return;

  refine(query.value);
}, SEARCH_TIMEOUT);

function createUrl(query) {
  return `${props.searchBaseUrl}?query=${encodeURIComponent(query)}`;
}

function handleEnter() {
  if (!query.value) return;

  window.location.href = createUrl(query.value);
}
onMounted(() => {
  if (window.innerWidth >= 1024) {
    isMobile.value = false;
  }
});
</script>
<style scoped lang="scss">
$component: "temp-search-bar";

.#{$component} {
  background-color: $white;
  position: relative;
  z-index: 23;
  &__container {
    position: fixed;
    z-index: -1;
    background-color: $white;
    top: -100dvh;
    left: 0;
    width: 100%;
    height: 100dvh;
    padding: 0 8px;
    transition: all 0.3s ease;
    &--active {
      top: $navigation-mobile-height;
    }
  }
  &__input {
    left: 0;
    width: 100%;
    border: 0;
    padding: 10px 15px;
    border: 2px solid $green;
    border-radius: 4px;
  }
}
</style>
