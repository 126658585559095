<template>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
    <g clip-path="url(#a)">
      <path
        fill="currentColor"
        d="m10 4.742 4.167 3.75V15H12.5v-5h-5v5H5.834V8.492L10 4.742ZM10 2.5 1.667 10h2.5v6.667h5v-5h1.667v5h5V10h2.5L10 2.5Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="currentColor" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
</template>
