<template>
  <Teleport to="#modal">
    <div v-if="open" class="c-modal" @click="handleClickModal">
      <div class="c-modal__content">
        <slot :close-modal="closeModal" />
      </div>
      <div class="c-modal__close">
        <close-button is-big is-grey-light @click="closeModal" />
      </div>
    </div>
  </Teleport>
</template>

<script setup>
import { ref, onMounted, defineExpose } from "vue";

const props = defineProps({
  isOpen: {
    type: Boolean,
    default: false,
  },
});

const open = ref(false);

const openModal = () => {
  open.value = true;
};

const closeModal = () => {
  open.value = false;
};

const handleClickModal = (event) => {
  const elementCloseList = ["c-modal", "c-modal__close", "c-modal__content"];

  if (
    elementCloseList.some((item) => [...event.target.classList].includes(item))
  ) {
    closeModal();
  }
};

defineExpose({
  openModal,
});

onMounted(() => {
  open.value = props.isOpen;
  if (props.isOpen) openModal();
});
</script>

<style lang="scss" scoped>
.c-modal {
  animation: fadeIn 0.4s ease 0s 1;
  background-color: rgba(0, 0, 0, 0.8);
  cursor: pointer;
  height: 100vh;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 999;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &__content {
    animation: 0.4s ease 0s 1 slideInFromBottom;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__close {
    position: absolute;
    right: 20px;
    top: 20px;
  }
}
</style>
