import { createApp } from "vue";
import store from "./../store";
import InstantSearch from "vue-instantsearch/vue3/es";
import { ObserveVisibility } from "vue-observe-visibility";
// eslint-disable-next-line import/no-unresolved
import useSentry from "@/sentry";
import registerSvgIcons from "../register-svg-icons";

// Create a Vue application instance
const app = createApp({
  mounted() {
    const elements = document.querySelectorAll("[data-observe]");
    const elementsBothWays = document.querySelectorAll(
      "[data-observe-two-way]"
    );
    const animationTitles = document.querySelectorAll("[animate-title]");

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("loaded");
          entry.target.classList.add("in-view");
        }
      });
    });

    const observerTwoWay = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("loaded");
          entry.target.classList.add("in-view");
        } else {
          entry.target.classList.remove("in-view");
        }
      });
    });

    const stringToNodeElements = (elements) => {
      elements.forEach((title) => {
        // Step 1: Wrap each word in a span with the class `word`, ignoring empty words
        title.innerHTML = title.textContent
          .split(/\s+/) // Split text into words
          .filter((word) => word.length > 0) // Filter out any empty words
          .map(
            (word) =>
              `<span class="word__wrapper"><span class="word__item">${word}</span></span>`
          )
          .join(" "); // Join words back with spaces

        // Step 2: Wrap each letter within each word span with `text-animation__animate-letter`
        // const words = title.querySelectorAll(".text-animation__word");
        // words.forEach((word) => {
        //     word.innerHTML = word.textContent.replace(
        //         /\S/g,
        //         "<span class='text-animation__animate-letter'>$&</span>"
        //     );
        // });

        // Add the `text-animation__wrapper` class to the target element
        title.classList.add("text-animation");
      });
    };

    elements.forEach((el) => observer.observe(el));
    elementsBothWays.forEach((el) => observerTwoWay.observe(el));
    stringToNodeElements(animationTitles);
  },
});

app.directive("observe-visibility", {
  beforeMount: (el, binding, vnode) => {
    vnode.context = binding.instance;
    ObserveVisibility.bind(el, binding, vnode);
  },
  update: ObserveVisibility.update,
  unmounted: ObserveVisibility.unbind,
});

// Automatically require and register components globally
const requireComponent = require.context("./", true, /\.vue$/);

requireComponent.keys().forEach((fileName) => {
  const componentConfig = requireComponent(fileName);

  const componentName = fileName
    .split("/")
    .pop()
    .replace(/\.\w+$/, "");

  // Register component globally
  app.component(componentName, componentConfig.default || componentConfig);
});

// Initialize Sentry
useSentry(app);

// register svg components
registerSvgIcons(app);

// Mount Vue application to the div with id="app"
app.use(InstantSearch);
app.use(store);
app.mount("#app");
