<template>
  <svg
    width="21"
    height="13"
    viewBox="0 0 21 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.04125 0.316626L0.75 2.66663L10.5 12.6666L20.25 2.66663L17.9587 0.316626L10.5 7.94996L3.04125 0.316626Z"
      fill="black"
    />
  </svg>
</template>
