<template>
  <div ref="container">
    <slot />
  </div>
</template>

<script setup>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ref, onMounted, onBeforeMount } from "vue";

gsap.registerPlugin(ScrollTrigger);

const container = ref(null);
const timeline = gsap.timeline();

const killTimeline = () => {
  if (!timeline.value) return;

  timeline.value.kill();
  timeline.value = null;
};

const setupTimeline = () => {
  const listItems = container.value.querySelectorAll(".gsap-list-item");

  if (!listItems.length) return;

  timeline.value = gsap.timeline({
    scrollTrigger: {
      trigger: container.value,
      start: "top center",
      end: "bottom center",
      once: true,
    },
  });

  timeline.value.fromTo(
    listItems,
    {
      autoAlpha: 0,
      yPercent: (index) => index * 10 + 100,
    },
    {
      yPercent: 0,
      autoAlpha: 1,
      stagger: 0.1,
      duration: 0.75,
      ease: "power2.out",
    }
  );
};

onMounted(() => {
  setupTimeline();
});

onBeforeMount(() => {
  killTimeline();
});
</script>
