<template>
  <section v-if="active" key="section" class="emergency-banner__section">
    <div class="container">
      <div class="emergency-banner">
        <div class="emergency-banner__icon">
          <img src="/assets/icons/emergency.svg" alt="emergency-icon" />
        </div>

        <div class="emergency-banner__content">
          <p class="paragraph paragraph--bold paragraph--white">
            {{ data.text }}
          </p>
          <a
            :href="data.url.url"
            :target="data.url.target"
            class="emergency-banner__cta"
            :aria-label="getAriaLinkText(data.url.url)"
          >
            <p class="paragraph paragraph--white">
              {{ data.url.text }}
            </p>
          </a>
        </div>

        <button class="emergency-banner__close" @click="active = false">
          ✕
        </button>
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref, onMounted } from "vue";
import getAriaLinkText from "../helpers.js";

defineProps({
  data: {
    required: true,
    type: Object,
  },
});

const active = ref(false);

onMounted(() => {
  setTimeout(() => {
    active.value = true;
  }, 1000);
});
</script>
