<template>
  <ais-instant-search
    :search-client="client"
    :index-name="searchIndex"
    :routing="routing"
    :future="{ preserveSharedStateOnUnmount: false }"
    :insights="true"
    :middlewares="middlewares"
  >
    <ais-configure
      :filters="searchFilters"
      :optional-filters.camel="searchFiltersOptional"
      :hits-per-page.camel="hitsPerPage"
    />
    <slot />
  </ais-instant-search>
</template>

<script setup>
import { ref, computed } from "vue";

import { history as historyRouter } from "instantsearch.js/es/lib/routers";
import { singleIndex as singleIndexMapping } from "instantsearch.js/es/lib/stateMappings";
import {
  searchClientService,
  searchClientPreloadedService,
  searchClient,
  searchClientPreloaded,
  SEARCH_QUERY_MIN_LENGTH,
  SEARCH_TIMEOUT,
} from "../../algoliaSearchClient";

const props = defineProps({
  searchIndex: {
    type: String,
    required: true,
  },
  searchFilters: {
    type: String,
    default: "",
  },
  isPreloaded: {
    type: Boolean,
    default: false,
  },
  hitsPerPage: {
    type: Number,
    default: 21,
  },
  isService: {
    type: Boolean,
    default: false,
  },
  hasRouting: {
    type: Boolean,
    default: true,
  },
  searchFiltersOptional: {
    type: String,
    default: "",
  },
});

const middlewares = ref([]);

const routing = ref(
  props.hasRouting
    ? {
        router: historyRouter(),
        stateMapping: singleIndexMapping(props.searchIndex),
      }
    : null
);

const client = computed(() => {
  return props.isPreloaded
    ? props.isService
      ? searchClientPreloadedService
      : searchClientPreloaded
    : props.isService
    ? searchClientService
    : searchClient;
});

const googleAnalyticsMiddleware = () => {
  let timer = null;
  const sendEventDebounced = (query) => {
    if (!query || query.length <= SEARCH_QUERY_MIN_LENGTH) {
      return;
    }

    clearTimeout(timer);

    timer = setTimeout(() => {
      const dataLayer = (window.dataLayer = window.dataLayer || []);
      dataLayer.push({
        event: "handle_search",
        search_term: query,
      });
      console.log("Google Analytics event sent:", {
        event: "handle_search",
        search_term: query,
      });
    }, SEARCH_TIMEOUT + 600);
  };

  return {
    onStateChange({ uiState }) {
      const query = uiState[props.searchIndex]?.query ?? "";
      sendEventDebounced(query);
    },
  };
};

middlewares.value.push(googleAnalyticsMiddleware);
</script>
