<template>
  <div>
    <nav
      class="events-navigation"
      :class="{
        'events-navigation--active': activeItem,
      }"
      tabindex="0"
      @keydown.esc="handleEscape"
    >
      <div
        v-click-outside="handleClickOut"
        class="events-navigation__container"
      >
        <slot
          name="main-content"
          :active-item="activeItem"
          :handle-click-menu-item="handleClickMenuItem"
          :handle-click-menu-item-first-level="handleClickMenuItemFirstLevel"
          :handle-mouse-over-menu-item="handleMouseOverMenuItem"
        />
      </div>
    </nav>
    <div v-if="activeItem" class="overlay" @click="setMenuItemActive('')"></div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { debounce } from "lodash";
import { userDeviceHasHover } from "../../mixins";

// const props = defineProps({
//     modifier: {
//         type: String,
//         default: "",
//     },
// });

const activeItem = ref("");

const handleClickOut = () => {
  activeItem.value = "";
};

const setMenuItemActive = (menuItem) => {
  activeItem.value = menuItem;
};

const handleClickMenuItem = (menuItem) => {
  setMenuItemActive(menuItem);
};

const handleClickMenuItemFirstLevel = (slug, url, nested, target = "_self") => {
  if (userDeviceHasHover.value || !nested) {
    setMenuItemActive("");
    window.open(url, target).focus();
    return;
  }

  setMenuItemActive(slug);
};

const handleEscape = () => {
  setMenuItemActive("");
};

// Debounced function
const handleMouseOverMenuItem = debounce((menuItem) => {
  setMenuItemActive(menuItem);
}, 300);

// Define the v-click-outside directive
const vClickOutside = {
  beforeMount(el, binding) {
    el.clickOutsideEvent = (event) => {
      if (!(el === event.target || el.contains(event.target))) {
        binding.value(event);
      }
    };
    document.addEventListener("click", el.clickOutsideEvent);
  },
  unmounted(el) {
    document.removeEventListener("click", el.clickOutsideEvent);
  },
};

// Register the directive globally inside setup()
defineExpose({ vClickOutside });
</script>
