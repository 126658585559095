<template>
  <span ref="refTextAnimation" class="text-animation__wrapper" animate-title>
    <slot />
  </span>
</template>
<script setup>
import { ref, onMounted, nextTick } from "vue";
import anime from "animejs/lib/anime.es.js";

const refTextAnimation = ref();

onMounted(() => {
  nextTick(() => {
    const words = Array.from(
      refTextAnimation.value.getElementsByClassName("word__item")
    );
    const tl = anime.timeline({
      targets: words,
      // eslint-disable-next-line
            delay: function (el, i) {
        return i * 130;
      },
      duration: 800,
      autoplay: true,
      easing: "easeOutQuint",
      direction: "normal",
    });

    tl.add({
      top: [200, 0],
      opacity: [0, 1],
    });
  });
});
</script>
<style scoped>
:deep(.word__wrapper) {
  overflow: hidden;
  position: relative;
}
:deep(.word__item) {
  position: relative;
  top: 100px;
}
</style>
