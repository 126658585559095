<template>
  <ais-instant-search
    :search-client="searchClient"
    :index-name="searchIndex + '_query_suggestions'"
    :future="{ preserveSharedStateOnUnmount: false }"
  >
    <ais-autocomplete v-slot="{ refine }">
      <div
        class="searchbar"
        :class="{
          'searchbar--active': isActive,
          'searchbar--hide-mobile': hideMobile,
        }"
      >
        <button :class="buttonClass" @click="updateActiveState">
          <slot name="icon" />

          <template v-if="!isActive">
            {{ labels.search }}
          </template>
        </button>

        <label for="search" class="sr-only">{{ t("Search...") }}</label>
        <input
          id="search"
          ref="input"
          v-model="query"
          type="text"
          name="search"
          class="searchbar__input"
          :class="{ 'searchbar__input--full-width': fullWidthInput }"
          @input="() => debounceInput(refine, query)"
          @keyup.enter="handleEnter"
          @blur="updateActiveState"
        />
      </div>
    </ais-autocomplete>
  </ais-instant-search>
</template>

<script setup>
import { debounce } from "lodash";
import {
  searchClient,
  SEARCH_QUERY_MIN_LENGTH,
  SEARCH_TIMEOUT,
} from "../../algoliaSearchClient";
import { ref } from "vue";
import { t } from "../../filters.js";

const props = defineProps({
  buttonClass: {
    type: [String, Array, Object],
    default: null,
  },
  searchIndex: {
    type: String,
    required: true,
  },
  searchBaseUrl: {
    type: String,
    required: true,
  },
  popularTerms: {
    type: Array,
    default: () => [],
  },
  labels: {
    type: Object,
    required: true,
  },
  fullWidthInput: {
    type: Boolean,
    default: false,
  },
  hideMobile: {
    type: Boolean,
    default: false,
  },
});

const input = ref();

const isActive = ref(false);
const showContent = ref(false);
const query = ref("");

const updateActiveState = () => {
  if (isActive.value) {
    setTimeout(() => (isActive.value = false), 200);
    showContent.value = false;
  } else {
    isActive.value = true;
    setTimeout(() => (showContent.value = true), 200);

    input.value?.focus();
  }

  showContent.value = false;
};

const debounceInput = debounce((refine, query) => {
  if (query.length <= SEARCH_QUERY_MIN_LENGTH) return;

  refine(query.value);
}, SEARCH_TIMEOUT);

const createUrl = (query) => {
  return `${props.searchBaseUrl}?query=${encodeURIComponent(query)}`;
};

const handleEnter = () => {
  if (!query.value) return;

  window.location = createUrl(query.value);
};
</script>
