<template>
  <svg
    width="13"
    height="16"
    viewBox="0 0 13 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.4285 8.79798L6.55222 15H5.66072L1.78446 8.79799C-0.337206 5.40332 2.10332 1 6.10647 1C10.1096 1 12.5501 5.40332 10.4285 8.79798ZM11.2765 9.32798L7.10647 16H5.10647L0.936461 9.32798C-1.60148 5.26727 1.31789 0 6.10647 0C10.8951 0 13.8144 5.26727 11.2765 9.32798ZM8.27832 6C8.27832 7.10457 7.38289 8 6.27832 8C5.17375 8 4.27832 7.10457 4.27832 6C4.27832 4.89543 5.17375 4 6.27832 4C7.38289 4 8.27832 4.89543 8.27832 6ZM9.27832 6C9.27832 7.65685 7.93517 9 6.27832 9C4.62147 9 3.27832 7.65685 3.27832 6C3.27832 4.34315 4.62147 3 6.27832 3C7.93517 3 9.27832 4.34315 9.27832 6Z"
      fill="currentColor"
    />
  </svg>
</template>
