<template>
  <div class="cta-icon-block">
    <div class="rfh-leafs is-icon">
      <i class="leaf leaf--right" />
      <i class="leaf leaf--left" />
    </div>
    <div class="cta-icon-block__text">
      <div>
        {{ title }}
      </div>

      <a
        v-for="(button, index) in buttons"
        :key="`cta-button-${index}`"
        :href="button.buttonUrl"
        data-track-category="call to action"
        :data-track-label="button.buttonText"
        :data-track-event="ctaName"
        class="text-button__uppercase"
        :aria-label="getAriaLinkText(button.buttonUrl)"
      >
        {{ button.buttonText }}
      </a>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import getAriaLinkText from "../helpers.js";

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  buttons: {
    type: Array,
    required: true,
  },
  entryType: {
    type: String,
    default: "",
  },
});

const ctaName = computed(() => {
  return props.entryType
    ? `${props.entryType}_mainNavigation_ctaIconBlock`
    : "";
});
</script>
