<template>
  <svg
    width="11"
    height="13"
    viewBox="0 0 11 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.1213 1.94547H8.61524V0.93335H7.60312V1.94547H2.54251V0.93335H1.53039V1.94547H1.02433C0.467662 1.94547 0.012207 2.40093 0.012207 2.95759V11.0546C0.012207 11.6112 0.467662 12.0667 1.02433 12.0667H9.1213C9.67797 12.0667 10.1334 11.6112 10.1334 11.0546V2.95759C10.1334 2.40093 9.67797 1.94547 9.1213 1.94547ZM9.12127 11.0545H1.0243V5.48787H9.12127V11.0545ZM1.0243 4.47576H9.12127V2.95758H1.0243V4.47576Z"
      fill="black"
    />
  </svg>
</template>
