<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0 1.7722C0 1.43633 0.284097 1.16406 0.634548 1.16406H15.3655C15.7159 1.16406 16 1.43633 16 1.7722C16 2.10806 15.7159 2.38033 15.3655 2.38033H0.634548C0.284097 2.38033 0 2.10806 0 1.7722Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0 8.25657C0 7.92071 0.284097 7.64844 0.634548 7.64844H15.3655C15.7159 7.64844 16 7.92071 16 8.25657C16 8.59243 15.7159 8.8647 15.3655 8.8647H0.634548C0.284097 8.8647 0 8.59243 0 8.25657Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0 14.5271C0 14.1912 0.284097 13.9189 0.634548 13.9189H15.3655C15.7159 13.9189 16 14.1912 16 14.5271C16 14.8629 15.7159 15.1352 15.3655 15.1352H0.634548C0.284097 15.1352 0 14.8629 0 14.5271Z"
      fill="white"
    />
    <path
      d="M6.95482 1.66674C6.95482 2.58725 6.17619 3.33347 5.21569 3.33347C4.2552 3.33347 3.47656 2.58725 3.47656 1.66674C3.47656 0.746224 4.2552 0 5.21569 0C6.17619 0 6.95482 0.746224 6.95482 1.66674Z"
      fill="white"
    />
    <path
      d="M13.9138 8.33226C13.9138 9.25278 13.1352 9.999 12.1747 9.999C11.2142 9.999 10.4355 9.25278 10.4355 8.33226C10.4355 7.41175 11.2142 6.66553 12.1747 6.66553C13.1352 6.66553 13.9138 7.41175 13.9138 8.33226Z"
      fill="white"
    />
    <path
      d="M4.86889 14.3332C4.86889 15.2538 4.09025 16 3.12976 16C2.16926 16 1.39062 15.2538 1.39062 14.3332C1.39062 13.4127 2.16926 12.6665 3.12976 12.6665C4.09025 12.6665 4.86889 13.4127 4.86889 14.3332Z"
      fill="white"
    />
  </svg>
</template>
