<template>
  <section v-if="items.length > 0">
    <header class="c-header c-header--is-green-200">
      <div class="c-header__visual"></div>
      <div class="c-header__content">
        <div class="c-header__back-link-container">
          <span class="c-header__back-link-icon">
            <svg-component name="chevron-left" />
          </span>
          <a :href="backLink" :aria-label="getAriaLinkText(backLink)">{{
            t("Terug naar {position}", {
              params: { position: backLabel },
            })
          }}</a>
        </div>
        <span class="u-margin-bottom-tiny">
          <h1 class="t-h1 t-h1--green-main">
            {{ items[0].title }}
          </h1>
        </span>
        <div class="p-service-detail">
          <div class="p-service-detail__category-container">
            <div
              v-for="(category, index) in categories"
              :key="category"
              class="p-service-detail__category"
            >
              <a
                v-if="categoryPageExists"
                :href="categoryUrl(index !== 0)"
                class="p-service-detail__category-link"
                :aria-label="getAriaLinkText(categoryUrl(index !== 0))"
              >
                {{ category }}
              </a>
              <span v-else class="p-service-detail__category-span">{{
                category
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </header>
    <div class="l-container">
      <article class="p-service-content">
        <!-- eslint-disable vue/no-v-html -->
        <div class="t-redactor" v-html="items[0].content"></div>
      </article>
      <!-- esline-enable -->
    </div>
  </section>
</template>

<script setup>
import { ref, computed, onBeforeMount } from "vue";
import { t } from "../../filters";
import getAriaLinkText from "../../helpers.js";

const props = defineProps({
  items: {
    type: Array,
    default: () => [],
  },
  parent: {
    type: Object,
    default: () => ({
      title: "Service en Contact",
      uri: "",
    }),
  },
  language: {
    type: String,
    default: null,
  },
  categoriesArray: {
    type: Array,
    default: null,
  },
});

const categoryItemUrl = ref(null);
const backLabel = ref(props.parent.title);

const setBackLabel = (label) => {
  backLabel.value = label;
};

const langSearchCheck = () => {
  switch (props.language) {
    case "EN":
      return "search";
    case "DE":
      return "suchen";
    case "NL":
      return "zoeken";
    default:
      return "zoeken";
  }
};

const categoryUrl = (withFilter) => {
  let url = categoryItemUrl.value[0];
  const params =
    "?refine=" + encodeURIComponent(props.items[0].categories.lvl1);

  if (withFilter) {
    url += params;
  }
  return url;
};

const stringToUri = (string) => {
  return string.replace(/[^\w]|_/g, "-").toLowerCase();
};

const titleUrl = () => {
  if (props.items.length === 0) {
    return;
  }
  const { pathname, origin } = window.location;
  const slug = pathname;
  const slugArray = slug.split("/");
  slugArray.splice(slugArray.length - 1, 1, stringToUri(props.items[0].title));
  const newSlug = slugArray.join("/");
  history.pushState({}, "", `${origin}${newSlug}`);
};

const checkTagObject = (tagObject) => {
  if (Object.keys(tagObject).length === 0 || tagObject.lvl1 === undefined) {
    return false;
  }
  return true;
};

const categories = computed(() => {
  if (props.items.length === 0) {
    return;
  }
  const tagObject = props.items[0].categories;
  if (!checkTagObject(tagObject)) {
    return;
  }
  const categoryString = tagObject.lvl1;
  const array = categoryString.split(" > ");
  return array;
});

const categoryPageExists = computed(() => {
  if (props.items.length === 0) {
    return false;
  }
  const tagObject = props.items[0].categories;
  if (!checkTagObject(tagObject)) {
    return false;
  }
  const item = props.categoriesArray.filter(
    (item) => item.title === props.items[0].categories.lvl0
  );

  return item.length > 0;
});

const backLink = computed(() => {
  const historyUrl = document.referrer;

  if (historyUrl.includes(`/${langSearchCheck()}`)) {
    setBackLabel(langSearchCheck());
    return historyUrl;
  }
  if (categoryPageExists.value) {
    if (historyUrl.includes(categoryUrl(false))) {
      setBackLabel(props.items[0].categories.lvl0);
      return historyUrl;
    }
  }
  return props.parent.uri;
});

onBeforeMount(() => {
  if (props.items.length === 0) {
    window.location.href = "/404";
  }
  titleUrl();
  if (categoryPageExists.value) {
    const item = props.categoriesArray.filter(
      (item) => item.title === props.items[0].categories.lvl0
    );
    if (item.length > 0) {
      categoryItemUrl.value = item.map((content) => content.url);
    }
  }
});
</script>
