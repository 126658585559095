<template>
  <component :is="handleSvgName" v-if="handleSvgName" />
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
});

const handleSvgName = computed(() => {
  const fixedName = props.name
    .split("-")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join("");
  return `Svg${fixedName}Icon`;
});
</script>
