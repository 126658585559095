<template>
  <div ref="container">
    <slot />
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount } from "vue";
import { gsap } from "gsap";
import { SplitText } from "gsap/SplitText";

gsap.registerPlugin(SplitText);

const MEDIA_QUERY_DESKTOP = "1024px";
const container = ref(null);
let timeline = null;

// Function to check if the viewport is narrow
const viewportIsNarrow = () =>
  window.matchMedia(`(max-width: ${MEDIA_QUERY_DESKTOP})`).matches;

// Kill GSAP timeline when needed
const killTimeline = () => {
  if (timeline) {
    timeline.kill();
    timeline = null;
  }
};

// Define all animation functions first
const animateLeafsOuter = (outerLeft, outerBottom) => {
  timeline.fromTo(
    outerLeft,
    { y: 50, x: -120, scaleX: 0.8, rotation: 6 },
    {
      y: 0,
      x: 0,
      scaleX: 1,
      rotation: 0,
      duration: 2.5,
      ease: "power3.out",
    },
    0
  );

  timeline.fromTo(
    outerBottom,
    { autoAlpha: 1, y: 150, x: -200, scaleY: 0, rotation: 10 },
    { y: 0, x: 0, scaleY: 1, duration: 2, rotation: 0, ease: "power2.out" },
    0
  );
};

const animateLeafs = (leafLeft, leafBottom) => {
  timeline.fromTo(
    leafLeft,
    { y: -160, x: -120, rotation: 12, scale: 0.9 },
    { y: 0, x: 0, rotation: 0, scale: 1, ease: "power2.out" },
    0
  );

  timeline.fromTo(
    leafBottom,
    { scale: 0.8, y: 60 },
    { scale: 1.1, y: 0, ease: "power2.out" },
    0
  );
};

const animateHeading = (element) => {
  const text = new SplitText(element, { types: "words, lines" });

  timeline.fromTo(
    viewportIsNarrow() ? text.words : text.lines,
    { y: 40, autoAlpha: 0 },
    {
      y: 0,
      autoAlpha: 1,
      duration: 1,
      ease: "power1.out",
      stagger: 0.2,
      onComplete: () => text.revert(),
    },
    "-=2.25"
  );
};

const animatePills = (elements) => {
  timeline.fromTo(
    elements,
    { x: -16, autoAlpha: 0 },
    { x: 0, autoAlpha: 1, duration: 1, ease: "power3.out", stagger: 0.1 },
    "-=1.25"
  );
};

const animateDescription = (element) => {
  const text = new SplitText(element, { types: "lines" });

  timeline.fromTo(
    text.lines,
    { autoAlpha: 0, y: 16 },
    {
      autoAlpha: 1,
      y: 0,
      duration: 1,
      ease: "power2.out",
      stagger: 0.1,
      onComplete: () => text.revert(),
    },
    "-=1"
  );
};

const animateButton = (elements) => {
  timeline.fromTo(
    elements,
    { y: 16, autoAlpha: 0 },
    { y: 0, autoAlpha: 1, duration: 1, ease: "power1.out", stagger: 0.06 },
    "-=1"
  );
};

// Now we define setupTimeline AFTER the animation functions
const setupTimeline = () => {
  killTimeline();

  if (!container.value) {
    console.warn("Container not found.");
    return;
  }

  timeline = gsap.timeline();
  const leafLeft = container.value.querySelector(".gsap-hero-leaf-left");
  const leafBottom = container.value.querySelector(".gsap-hero-leaf-bottom");
  const leafLeftOuter = container.value.querySelector(
    ".gsap-hero-leaf-left-outer"
  );
  const leafBottomOuter = container.value.querySelector(
    ".gsap-hero-leaf-bottom-outer"
  );
  const mainHeading = container.value.querySelector(".gsap-hero-title");
  const pills = container.value.querySelectorAll(".gsap-hero-pill");
  const description = container.value.querySelector(".gsap-hero-description");
  const buttons = container.value.querySelectorAll(".gsap-hero-button");

  if (!viewportIsNarrow()) {
    if (leafLeftOuter && leafBottomOuter)
      animateLeafsOuter(leafLeftOuter, leafBottomOuter);
    if (leafLeft && leafBottom) animateLeafs(leafLeft, leafBottom); // ✅ Fix: Use leafBottom here
  }

  if (mainHeading) animateHeading(mainHeading);
  if (pills.length) animatePills(pills);
  if (description) animateDescription(description);
  if (buttons.length) animateButton(buttons);
};

// Lifecycle Hooks
onMounted(() => {
  setupTimeline();
});

onBeforeUnmount(() => {
  killTimeline();
});
</script>
