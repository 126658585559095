<template>
  <Teleport to="#emergency-pop-up">
    <div
      v-if="open"
      :class="[
        'c-emergency-pop-up',
        {
          'c-emergency-pop-up--closed': hasBeenClosed,
        },
      ]"
    >
      <div class="c-emergency-pop-up__content">
        <div class="c-emergency-pop-up__close">
          <close-button @click="closeModal" />
        </div>

        <div class="c-emergency-pop-up__title">
          <div class="c-emergency-pop-up__icon">
            <svg-component name="exclamation-mark" />
          </div>

          <slot name="title" />
        </div>
        <div class="c-emergency-pop-up__cta">
          <slot name="cta" />
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script setup>
import { ref, onMounted } from "vue";

const open = ref(false);
const hasBeenClosed = ref(false);

const closeModal = () => {
  hasBeenClosed.value = true;
  setTimeout(() => {
    open.value = false;
  }, 700);
};

onMounted(() => {
  setTimeout(() => {
    open.value = true;
  }, 2200);
});
</script>

<style scoped lang="scss">
.c-emergency-pop-up {
  position: fixed;
  top: $navigation-mobile-height + 20px;
  z-index: 70;
  left: 0;
  right: 0;
  pointer-events: none;

  @include full-grid;

  @include tablet {
    top: $navigation-mobile-height + 60px;
  }

  &__content {
    pointer-events: all;
    animation: 0.2s cubic-bezier(0, 0, 0.08, 0.99) slideInFromRight;
    position: relative;
    border-left: 7px solid $red-main;
    background-color: $red-200;
    padding: 16px 44px 16px 24px;
    border-radius: $border-radius-standard;
    grid-column: main-start / main-end;
    box-shadow: $card-shadow;

    @include tablet {
      width: 360px;
      justify-self: flex-end;
    }

    @include widescreen {
      grid-column: 1 / -1;
    }
  }

  &__close {
    position: absolute;
    top: 20px;
    right: 20px;
  }

  &__title {
    color: $red-main;
    display: flex;
    column-gap: 8px;
    font-weight: 600;
  }

  &__icon {
    margin-top: 2px;
  }

  &__cta {
    padding-left: 34px;
  }

  &--closed &__content {
    opacity: 0;
    animation: 0.2s cubic-bezier(0, 0, 0.08, 0.99) fadeOut;
  }
}
</style>
